import "./RefreshModal.css";

const PreviousConfirmModal = ({
  showPreviousModal,
  handlePreviousModalClose,
  handlePrevious,
}) => {
  return (
    <>
      {showPreviousModal && (
        <div className="modal-overlay" onClick={handlePreviousModalClose}>
          <div className="outer-modal" onClick={(e) => e.stopPropagation()}>
            <p className="zeroMargin refresh-modal-header">Are you sure?</p>

            <p className="refresh-modal-para">
              You are going to navigate back to the previous step
            </p>

            <div className="flex">
              <button
                className="button cancel-btn"
                onClick={handlePreviousModalClose}
                type="button"
              >
                No
              </button>
              <button
                className="button reset-btn"
                onClick={handlePrevious}
                type="button"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviousConfirmModal;
