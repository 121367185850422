import React from "react";
import { Col, Form, FormLabel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { truncateText } from "../../Utils/helperFunction";

function Input({
  Label,
  Description,
  Placeholder,
  Name,
  onChange,
  value,
  width,
  required,
  margin,
  Error,
  decimal,
  customExchangeError,
  dividendCustomTokenError,
  CustomInputError,
  tokenSymbol,
  tokenSymbolValue,
  onKeyDown,
  maxLength,
}) {
  return (
    <Col sm={12} md={width} className="mb-4 mb-md-0">
      <FormLabel
        className={`${margin} d-flex align-items-start`}
        style={{ color: "#45D09A" }}
      >
        {Label}
        {required && <p style={{ color: "#F00" }}>*</p>}
      </FormLabel>

      <div style={{ position: tokenSymbol ? "relative" : "" }}>
        <Form.Control
          className="mt-2 placeholderColor"
          style={{
            color: "#FFF",
            borderColor: "#45D09A",
            backgroundColor: "#45D09A33",
            boxShadow: "none",
          }}
          type="text"
          placeholder={Placeholder}
          name={Name}
          onChange={onChange}
          value={value}
          disabled={decimal == "disable"}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
        />
        {tokenSymbol && (
          <p
            style={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              color: "#45D09A",
            }}
          >
            {truncateText(tokenSymbolValue, 6)}
          </p>
        )}
      </div>
      <div
        style={{
          color:
            customExchangeError || dividendCustomTokenError || CustomInputError
              ? "red"
              : "#45D09A",
        }}
      >
        {Description}
      </div>
      {Error && <div style={{ color: "red" }}>{Error}</div>}
    </Col>
  );
}

export default Input;
