import { createSlice } from "@reduxjs/toolkit";

const DefaultExchangeSlice = createSlice({
  name: "defaultExchange",
  initialState: {
    defaultExchange: false,
    defaultExchangeSelect: "",
    customExchangeInput: "",
    selectedOptionData: null,
  },
  reducers: {
    setDefaultExchange: (state, action) => {
      const { name, checked } = action.payload;
      state[name] = checked;
    },
    setDefaultExchangeSelect: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setCustomExchangeInput: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setSelectedOptionData: (state, action) => {
      state.selectedOptionData = action.payload;
    },
  },
});

export const {
  setDefaultExchange,
  setDefaultExchangeSelect,
  setCustomExchangeInput,
  setSelectedOptionData,
} = DefaultExchangeSlice.actions;

export default DefaultExchangeSlice.reducer;
