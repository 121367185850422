// KeyDown Function for only mix of characters and integers only
export const handleAlphanumericKeyDown = (event) => {
  const allowedKeys = /[a-zA-Z0-9]/;
  if (!allowedKeys.test(event.key) && event.key.length === 1) {
    event.preventDefault();
  }
};

// handle Key Down Function for the allowing digits upto maxlength 2
export const handleNumericKeyDown = (event, maxLength, currentValue = "") => {
  // Allow only numbers, backspace, delete, arrow keys, and tab
  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
  const isNumberKey = event.key >= "0" && event.key <= "9";

  if (!isNumberKey && !allowedKeys.includes(event.key)) {
    event.preventDefault();
  }

  // If maxLength is defined, prevent typing beyond this length
  if (maxLength && isNumberKey && currentValue.length >= maxLength) {
    event.preventDefault();
  }
};

// Hanlding Initial Supply
export const handleInitialSupplyKeyDown = (
  event,
  maxLength,
  currentValue = ""
) => {
  // Allow only numbers, backspace, delete, arrow keys, and tab
  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
  const isNumberKey = event.key >= "0" && event.key <= "9";

  // Prevent default action if the key is not a number or an allowed control key
  if (!isNumberKey && !allowedKeys.includes(event.key)) {
    event.preventDefault();
  }

  // Enforce maxLength restriction
  if (maxLength && isNumberKey && currentValue.length >= maxLength) {
    event.preventDefault();
  }
};
