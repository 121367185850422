import React from "react";
import InputHeader from "./InputHeader";
import CopyIcon from "../../Images/Logos/copy.svg";
import InputGroup from "./InputGroup";
import OwnerOnlyWrapper from "./OwnerOnlyWrapper/OwnerOnlyWrapper";
const Ownable = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Ownable</p>
      <InputHeader
        label="Owner"
        subHeading="owner( ) - Current owner address of AT token contract"
      />
      <div className="d-flex align-items-center gap-2 my-3">
        <p style={{ color: "#0AE7FE", wordBreak: "break-word" }}>
          0x35c68bFd0b55793baD663D6b292C7b9144ab55B8
        </p>
        <img src={CopyIcon} alt="copy" />
      </div>
      {/* <div
        className="p-3 my-2"
        style={{ background: "rgba(255, 255, 255, 0.04)", borderRadius: "8px" }}
      >
        <div className="d-flex align-items-center justify-content-end gap-2">
          <img src={AccountIcon} alt="account" />
          <p style={{ color: "#D7D7D7" }}>Owner Only</p>
        </div>
        <InputGroup
          Label="Transfer Ownership"
          subHeading={"transfer( to, amount ) - Transfer your AT"}
          InputLabel1="To"
          InputPlaceHolder1="0x......."
          ButtonLabel="Transfer Ownership"
        />
        <InputGroup
          Label="Renounce Ownership"
          subHeading={
            "renounceOwnership( ) - Renounce (Revoke) ownership of AT token contract forever"
          }
          ButtonLabel="Renounce Ownership"
          ButtonColor="#F05252"
          margin="mt-5"
        />
      </div> */}
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Transfer Ownership"
          subHeading={"transfer( to, amount ) - Transfer your AT"}
          InputLabel1="To"
          InputPlaceHolder1="0x......."
          ButtonLabel="Transfer Ownership"
        />
        <InputGroup
          Label="Renounce Ownership"
          subHeading={
            "renounceOwnership( ) - Renounce (Revoke) ownership of AT token contract forever"
          }
          ButtonLabel="Renounce Ownership"
          ButtonColor="#F05252"
          margin="mt-5"
        />
      </OwnerOnlyWrapper>
    </div>
  );
};

export default Ownable;
