import { createSlice } from "@reduxjs/toolkit";

const AppSlice = createSlice({
  name: "app",
  initialState: {
    generalApi: false,
    optionalApi: false,
    taxesApi: false,
  },

  reducers: {
    setGeneralApi: (state) => {
      state.generalApi = !state.generalApi;
    },
    setOptionalApi: (state) => {
      state.optionalApi = !state.optionalApi;
    },
    setTaxesApi: (state) => {
      state.taxesApi = !state.taxesApi;
    },
  },
});

export const { setOptionalApi, setTaxesApi, setGeneralApi } = AppSlice.actions;
export default AppSlice.reducer;
