import { Outlet } from "react-router";
import NavigationBar from "./Components/NavigationBar";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Screens/Home";
import MainApp from "./MainApp";
import CreateTokens from "./Screens/CreateTokens";
import Dashboard from "./Screens/Dashboard";
import Manage from "./Screens/Manage";
import TransactionSent from "./Screens/TransactionSent";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import SplToken from "./Screens/SplToken";
import TonToken from "./Screens/TonToken";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <h1>Not Found</h1>,
  },
  {
    path: "/createtoken",
    element: <MainApp />,
    children: [
      {
        path: "/createtoken",
        element: <CreateTokens />,
      },
    ],
  },
  // {
  //   path: "/spltoken",
  //   element: <MainApp />,
  //   children: [
  //     {
  //       path: "/spltoken",
  //       element: <SplToken />,
  //     },
  //   ],
  // },
  {
    path: "/tontoken",
    element: <MainApp />,
    children: [
      {
        path: "/tontoken",
        element: <TonToken />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <MainApp />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "manage/:id",
        element: <Manage />,
      },
    ],
  },
  {
    path: "/transcationcomplete",
    element: <TransactionSent />,
  },
]);

function App() {
  return <RouterProvider router={appRouter} />;
}

export default App;
