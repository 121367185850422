import AccountIcon from "../../../Images/Logos/account.svg";

const OwnerOnlyWrapper = ({ children }) => {
  return (
    <div
      className="p-3 my-2"
      style={{ background: "rgba(255, 255, 255, 0.04)", borderRadius: "8px" }}
    >
      <div className="d-flex align-items-center justify-content-end gap-2">
        <img src={AccountIcon} alt="account" />
        <p style={{ color: "#D7D7D7" }}>Owner Only</p>
      </div>
      {children}
    </div>
  );
};

export default OwnerOnlyWrapper;
