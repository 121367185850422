import React, { useEffect, useState } from "react";
// import SwitchInput from "../FormsComponents/SwitchInput";
import Switch from "../FormsComponents/Switch";
import { Button, Col, Form, Row } from "react-bootstrap";
import "./DefaultExchange.css";
// import SwitchCheckBox from "../FormsComponents/SwitchCheckBox";
import { useDispatch, useSelector } from "react-redux";
import { setOptionalApi } from "../../Store/Slices/AppSlice";
import "../FormsComponents/Switch.css";

import Input from "../FormsComponents/Input";
import CooldownToggle from "../FormsComponents/CooldownToggle";
import SwitchCheckboxInput from "../FormsComponents/SwitchCheckboxInput";
import axios from "axios";
import { scrollToTop } from "../../Utils/helperFunction";
import {
  setDefaultExchange,
  setDefaultExchangeSelect,
  setCustomExchangeInput,
  setSelectedOptionData,
} from "../../Store/Slices/DefaultExchangeSlice";
import RefreshModal from "../RefreshModal";
import RefreshIcon from "../../Images/Logos/Refresh.svg";
import { Routeraddress } from "./router";
import Web3 from "web3";
import InfoIcon from "../../Images/Logos/infoicon.svg";
import GlobalIcon from "../../Images/Logos/globalicon.svg";
import ExploreIcon from "../../Images/Logos/explorericon.png";

import { truncateText } from "../../Utils/helperFunction";
import { resetAll } from "../../Utils/helperFunction";

import { setResetPayment } from "../../Store/Slices/ResetPaymentSlice";
import useWalletInfo from "../../Hooks/WalletInfo/useWalletInfo";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DefaultExchange({ setTab, setStepper }) {
  const dispatch = useDispatch();

  const { chainId, isConnected } = useWalletInfo();

  // Getting Chain ID

  // const [chainID, setChainID] = useState(null);
  // const [selectedOptionData, setSelectedOptionData] = useState(null);

  // useEffect(() => {
  //   const initializeWeb3 = async () => {
  //     try {
  //       // Check if MetaMask is installed
  //       if (typeof window.ethereum !== "undefined") {
  //         // Initialize Web3 with MetaMask's provider
  //         const web3 = new Web3(window.ethereum);

  //         // Get the initial chain ID
  //         const initialChainId = await web3.eth.getChainId();
  //         setChainID(initialChainId);
  //         localStorage.setItem("chainID", initialChainId);
  //         // console.log("Initial Chain ID:", initialChainId);

  //         // Listen for chain ID changes
  //         window.ethereum.on("chainChanged", (newChainId) => {
  //           const decimalChainId = parseInt(newChainId, 16);
  //           setChainID(decimalChainId);
  //           dispatch(setSelectedOptionData(null));
  //           localStorage.setItem("chainID", decimalChainId);
  //           // console.log("Updated Chain ID ", newChainId);
  //         });
  //         // Clean up the event listener when the component unmounts
  //         return () => {
  //           window.ethereum.off("chainChanged");
  //         };
  //       } else {
  //         console.warn("MetaMask is not installed");

  //         // Get the chainID from localStorage if MetaMask is not available
  //         const storedChainId = localStorage.getItem("chainID");

  //         // If there is a stored chain ID, update the state
  //         if (storedChainId) {
  //           setChainID(parseInt(storedChainId, 10)); // Convert stored value to integer
  //           console.log("Using stored Chain ID:", storedChainId);
  //         } else {
  //           console.log("No chain ID found in localStorage");
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Chain ID Error in Default Excahnge", error);
  //     }
  //   };

  //   initializeWeb3();
  // }, [chainID]);

  const Options = Routeraddress[chainId];
  console.log("Router Address", Routeraddress[chainId]);
  // Getting Token Symbol from Redux
  const tokenSymbol = useSelector((state) => state.general.tokenSymbol);

  const tokenName = localStorage.getItem("tokenName") || "";
  const walletAddress = localStorage.getItem("walletAddress") || "";
  const ID = localStorage.getItem("ID") || "";
  // console.log("tokenName,walletAddress,ID", tokenName, walletAddress, ID);

  const initialSupply = useSelector((store) =>
    store.general.initialSupply?.replace(/\s/g, "")
  );
  const initialSupplyNumber = parseInt(initialSupply, 10);
  // console.log("Formatted Initial Supply :: ", initialSupplyNumber);

  let maxamountperWalletInputId,
    maxTransactionLimitInputId,
    onBuyingCheckInputId,
    onSellingCheckInputId,
    onTransferringCheckinputId,
    customExchangeInputId;

  const [errors, setErrors] = useState({});
  // State for UseEffect when reset happens then get the data from localstorage
  const [isReset, setIsReset] = useState(false);

  const [OptionalFormData, setOptionalFormData] = useState({
    defaultExchange: false,
    defaultExchangeSelect: "",
    customExchangeInput: "",
    antibotCooldown: false,
    antibotCooldownInput: "",
    antibotCoolDownTime: "Seconds",
    enbletradingFunction: false,
    maxamountperWallet: false,
    maxamountperWalletInput: "",
    maxTransactionLimit: false,
    maxTransactionLimitInput: "",
    onBuyingCheck: false,
    onBuyingCheckInput: "",
    onSellingCheck: false,
    onSellingCheckInput: "",
    onTransferringCheck: false,
    onTransferringCheckinput: "",
    pausable: false,
    blacklist: false,
    foregintokenRecovery: false,
    permit: false,
  });

  // Getting Toggle State from Redux
  const {
    defaultExchange,
    defaultExchangeSelect,
    customExchangeInput,
    selectedOptionData,
  } = useSelector((state) => state.defaultExchange);

  // console.log("Router Address Link", selectedOptionData?.Routeraddress);
  // Calculate 0.1% of initial supply
  const minValueForMaxWallet = Math.floor(initialSupplyNumber * 0.001);
  // console.log("0.001 % of Initial Supply::", minValueForMaxWallet);

  // Calculate 0.05% of initial supply
  const minValueForMaxTransaction = Math.floor(initialSupplyNumber * 0.0005);
  // console.log("0.05% of Initial Supply ::", minValueForMaxTransaction);
  const validateForm = () => {
    let formErrors = {};

    // Validation for Default Exchange Select
    if (defaultExchange == true) {
      if (defaultExchangeSelect === "") {
        formErrors.defaultExchangeSelect = "Please select a default exchange.";
      }
    }
    // Validation for Max Amount per Wallet
    if (OptionalFormData.maxamountperWallet) {
      const numericValue = parseInt(
        OptionalFormData.maxamountperWalletInput?.replace(/\s/g, ""),
        10
      );
      if (numericValue < minValueForMaxWallet) {
        formErrors.maxamountperWalletInput =
          "0.1% of Initial supply ≤ Max wallet amount ≤ Initial supply";
      } else if (numericValue > initialSupply) {
        formErrors.maxamountperWalletInput =
          "0.1% of Initial supply ≤ Max wallet amount ≤ Initial supply";
      }
      if (!OptionalFormData.maxamountperWalletInput) {
        formErrors.maxamountperWalletInput =
          "Enter Valid Max Amount Per Wallet Input";
      }
    }

    // Validation for Max Transaction Limit Input
    if (
      defaultExchange == false &&
      OptionalFormData.maxTransactionLimit == true
    ) {
      const numericValue = parseInt(
        OptionalFormData.maxTransactionLimitInput?.replace(/\s/g, ""),
        10
      );

      if (numericValue < minValueForMaxTransaction) {
        formErrors.maxTransactionLimitInput =
          "0.05% of Initial supply ≤ Max transaction limit ≤ Initial supply";
      } else if (numericValue > initialSupply) {
        formErrors.maxTransactionLimitInput =
          "0.05% of Initial supply ≤ Max transaction limit ≤ Initial supply";
      }
      if (!OptionalFormData.maxTransactionLimitInput) {
        formErrors.maxTransactionLimitInput =
          "Enter Valid Max transaction limit";
      }
    }

    // Validation for Max Transcation Limit Checkbox
    if (
      defaultExchange == true &&
      OptionalFormData.maxTransactionLimit == true
    ) {
      // Check if onBuying checkbox is true
      if (OptionalFormData.onBuyingCheck) {
        const numericValue = parseInt(
          OptionalFormData.onBuyingCheckInput?.replace(/\s/g, ""),
          10
        );

        if (numericValue < minValueForMaxTransaction) {
          formErrors.onBuyingCheckInput =
            "0.05% of initial supply<= Max buy limit<= Initial supply";
        } else if (numericValue > initialSupply) {
          formErrors.onBuyingCheckInput =
            "0.05% of initial supply<= Max buy limit<= Initial supply";
        }
        if (!OptionalFormData.onBuyingCheckInput) {
          formErrors.onBuyingCheckInput =
            "0.05% of initial supply<= Max buy limit<= Initial supply";
        }
      }
    }

    if (
      defaultExchange == true &&
      OptionalFormData.maxTransactionLimit == true
    ) {
      // Check if Onselling checkbox is true
      if (OptionalFormData.onSellingCheck) {
        const numericValue = parseInt(
          OptionalFormData.onSellingCheckInput?.replace(/\s/g, ""),
          10
        );

        if (numericValue < minValueForMaxTransaction) {
          formErrors.onSellingCheckInput =
            "0.05% of initial supply<= Max sell limit<= Initial supply";
        } else if (numericValue > initialSupply) {
          formErrors.onSellingCheckInput =
            "0.05% of initial supply<= Max sell limit<= Initial supply";
        }
        if (!OptionalFormData.onSellingCheckInput) {
          formErrors.onSellingCheckInput =
            "0.05% of initial supply<= Max sell limit<= Initial supply";
        }
      }
    }

    if (
      defaultExchange == true &&
      OptionalFormData.maxTransactionLimit == true
    ) {
      // Check if onTransferring checkbox is true
      if (OptionalFormData.onTransferringCheck) {
        const numericValue = parseInt(
          OptionalFormData.onTransferringCheckinput?.replace(/\s/g, ""),
          10
        );

        if (numericValue < minValueForMaxTransaction) {
          formErrors.onTransferringCheckinput =
            "0.05% of initial supply<= Max transfer limit<= Initial supply";
        } else if (numericValue > initialSupply) {
          formErrors.onTransferringCheckinput =
            "0.05% of initial supply<= Max transfer limit<= Initial supply";
        }
        if (!OptionalFormData.onTransferringCheckinput) {
          formErrors.onTransferringCheckinput =
            "0.05% of initial supply<= Max transfer limit<= Initial supply";
        }
      }
    }

    if (defaultExchange == true && defaultExchangeSelect == "Custom exchange") {
      const value = customExchangeInput;
      const isValidAddress = /^0x[a-fA-F0-9]{40}$/.test(value);
      if (!isValidAddress) {
        formErrors.customExchangeInput = "Enter valid RouterV2 address.";
      }
      if (!customExchangeInput) {
        formErrors.customExchangeInput = "Enter valid RouterV2 address.";
      }
    }

    // Validation for Anti Bot CoolDown
    if (defaultExchange == true) {
      if (OptionalFormData.antibotCooldown == true) {
        if (!OptionalFormData.antibotCooldownInput) {
          formErrors.antibotCooldownInput =
            "0 seconds (disabled) ≤ Anti-bot cooldown ≤ 12 hours";
        } else {
          const interval = parseInt(OptionalFormData.antibotCooldownInput, 10);
          const unit = OptionalFormData.antibotCoolDownTime;

          let isValid = false;
          let totalSeconds = 0;

          switch (unit) {
            case "Seconds":
              totalSeconds = interval;
              break;
            case "Minutes":
              totalSeconds = interval * 60;
              break;
            case "Hours":
              totalSeconds = interval * 3600;
              break;
            default:
              isValid = false;
          }

          // Validate if totalSeconds is between 0 and 43200 (12 hours in seconds)
          if (totalSeconds >= 0 && totalSeconds <= 43200) {
            isValid = true;
          }

          if (!isValid) {
            formErrors.antibotCooldownInput =
              "0 seconds (disabled) ≤ Anti-bot cooldown ≤ 12 hours";
          }
        }
      }
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  // Event handler for form input changes
  let localStorageData = JSON.parse(localStorage.getItem("formData"));

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (maxamountperWalletInputId) {
      clearTimeout(maxamountperWalletInputId);
    }

    if (maxTransactionLimitInputId) {
      clearTimeout(maxTransactionLimitInputId);
    }

    if (onBuyingCheckInputId) {
      clearTimeout(onBuyingCheckInputId);
    }

    if (onSellingCheckInputId) {
      clearTimeout(onSellingCheckInputId);
    }

    if (onTransferringCheckinputId) {
      clearTimeout(onTransferringCheckinputId);
    }

    if (name === "defaultExchangeSelect") {
      dispatch(setDefaultExchangeSelect({ name: name, value: value }));
      const selectedOption = Options.find((option) => option.name === value);
      setOptionalFormData({ ...OptionalFormData, [name]: [value] });
      // setSelectedOptionData(selectedOption);
      dispatch(setSelectedOptionData(selectedOption));
      localStorageData.defaultExchangeSelect = "";
      localStorage.setItem("formData", JSON.stringify(localStorageData));
    }
    if (name === "customExchangeInput") {
      dispatch(setCustomExchangeInput({ name: name, value: value }));
      customExchangeInputId = setTimeout(() => {
        const isValidAddress = /^0x[a-fA-F0-9]{40}$/.test(value);
        if (!isValidAddress) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            customExchangeInput: "Enter valid RouterV2 address.",
          }));
        }
      }, 500);
    }

    const formatValue = (val) =>
      val
        ?.replace(/[^\d.]/g, "") // Allow only digits and a decimal point
        ?.replace(/(\.\d)(.*)$/, "$1") // Allow only one digit after the decimal point
        ?.replace(/(\..*)\./g, "$1") // Prevent more than one decimal point
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, " "); // Add spaces for thousands

    // Validation for Max Transaction Limit Input
    if (name === "maxTransactionLimitInput") {
      let formattedValue = formatValue(value);

      if (formattedValue.length > 16) {
        return;
      }

      const numericValue = parseFloat(formattedValue?.replace(/\s/g, ""));

      if (numericValue >= minValueForMaxTransaction) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          maxTransactionLimitInput:
            "0.1% of Initial supply ≤ Max transaction limit ≤ Initial supply",
        }));
      } else if (numericValue <= initialSupply) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          maxTransactionLimitInput: `0.1% of Initial supply ≤ Max transaction limit ≤ Initial supply`,
        }));
      }

      setOptionalFormData({ ...OptionalFormData, [name]: formattedValue });

      maxTransactionLimitInputId = setTimeout(() => {
        if (
          numericValue >= minValueForMaxTransaction &&
          numericValue <= initialSupply
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            maxTransactionLimitInput: "",
          }));
        }
      }, 500);
    }
    // Validation for Max amount per wallet Input
    else if (name === "maxamountperWalletInput") {
      let formattedValue = formatValue(value);

      if (formattedValue.length > 16) {
        return;
      }

      const numericValue = parseFloat(formattedValue?.replace(/\s/g, ""));

      if (numericValue >= minValueForMaxWallet) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          maxamountperWalletInput:
            "0.1% of Initial supply ≤ Max wallet amount ≤ Initial supply",
        }));
      } else if (numericValue <= initialSupply) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          maxamountperWalletInput: `0.1% of Initial supply ≤ Max wallet amount ≤ Initial supply`,
        }));
      }

      setOptionalFormData({ ...OptionalFormData, [name]: formattedValue });

      maxamountperWalletInputId = setTimeout(() => {
        if (
          numericValue >= minValueForMaxWallet &&
          numericValue <= initialSupply
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            maxamountperWalletInput: "",
          }));
        }
      }, 500);
    }
    // Validation for Anti-Bot CoolDown
    else if (name === "antibotCooldownInput") {
      const formattedValue = value?.replace(/\D/g, "");
      if (formattedValue.length > 5) {
        return;
      }
      const interval = parseInt(formattedValue, 10);
      const unit = OptionalFormData.antibotCoolDownTime;

      let isValid = false;
      let totalSeconds = 0;

      switch (unit) {
        case "Seconds":
          totalSeconds = interval;
          break;
        case "Minutes":
          totalSeconds = interval * 60;
          break;
        case "Hours":
          totalSeconds = interval * 3600;
          break;
        default:
          isValid = false;
      }

      // Validate if totalSeconds is between 0 and 43200 (12 hours in seconds)
      if (totalSeconds >= 0 && totalSeconds <= 43200) {
        isValid = true;
      }

      if (!isValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          antibotCooldownInput:
            "0 seconds (disabled) ≤ Anti-bot cooldown ≤ 12 hours",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          antibotCooldownInput: "",
        }));
      }
      setOptionalFormData({ ...OptionalFormData, [name]: formattedValue });
    }
    // validation for Max Transaction Limit (on-buying)
    else if (name === "onBuyingCheckInput") {
      let formattedValue = formatValue(value);

      if (formattedValue.length > 16) {
        return;
      }

      const numericValue = parseFloat(formattedValue?.replace(/\s/g, ""));

      if (numericValue >= minValueForMaxTransaction) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          onBuyingCheckInput:
            "0.1% of Initial supply ≤ Max transaction limit ≤ Initial supply",
        }));
      } else if (numericValue <= initialSupply) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          onBuyingCheckInput: `0.1% of Initial supply ≤ Max transaction limit ≤ Initial supply`,
        }));
      }

      setOptionalFormData({ ...OptionalFormData, [name]: formattedValue });

      onBuyingCheckInputId = setTimeout(() => {
        if (
          numericValue >= minValueForMaxTransaction &&
          numericValue <= initialSupply
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            onBuyingCheckInput: "",
          }));
        }
      }, 500);
    }

    // validation for Max Transaction Limit (on-Selling)
    else if (name === "onSellingCheckInput") {
      let formattedValue = formatValue(value);

      if (formattedValue.length > 16) {
        return;
      }

      const numericValue = parseFloat(formattedValue?.replace(/\s/g, ""));

      if (numericValue >= minValueForMaxTransaction) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          onSellingCheckInput:
            "0.1% of Initial supply ≤ Max transaction limit ≤ Initial supply",
        }));
      } else if (numericValue <= initialSupply) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          onSellingCheckInput: `0.1% of Initial supply ≤ Max transaction limit ≤ Initial supply`,
        }));
      }

      setOptionalFormData({ ...OptionalFormData, [name]: formattedValue });

      onSellingCheckInputId = setTimeout(() => {
        if (
          numericValue >= minValueForMaxTransaction &&
          numericValue <= initialSupply
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            onSellingCheckInput: "",
          }));
        }
      }, 500);
    }
    // validation for Max Transaction Limit (On-Transferring)
    else if (name === "onTransferringCheckinput") {
      let formattedValue = formatValue(value);

      if (formattedValue.length > 16) {
        return;
      }

      const numericValue = parseFloat(formattedValue?.replace(/\s/g, ""));

      if (numericValue >= minValueForMaxTransaction) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          onTransferringCheckinput:
            "0.05% of initial supply<= Max transfer limit<= Initial supply",
        }));
      } else if (numericValue <= initialSupply) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          onTransferringCheckinput:
            "0.05% of initial supply<= Max transfer limit<= Initial supply",
        }));
      }

      setOptionalFormData({ ...OptionalFormData, [name]: formattedValue });

      onTransferringCheckinputId = setTimeout(() => {
        if (
          numericValue >= minValueForMaxTransaction &&
          numericValue <= initialSupply
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            onTransferringCheckinput: "",
          }));
        }
      }, 500);
    } else {
      setOptionalFormData({ ...OptionalFormData, [name]: value });
    }

    // Check if the field being updated is not 'defaultExchangeSelect'
    if (name !== "defaultExchangeSelect") {
      const updatedFormData = {
        ...localStorageData,
        [name]: [
          "antibotCooldownInput",
          "antibotCoolDownTime",
          "customExchangeInput",
        ].includes(name)
          ? value
          : formatValue(value),
      };
      dispatch(setResetPayment(true));
      // Store the updated object in local storage
      localStorage.setItem("formData", JSON.stringify(updatedFormData));
    }
  };

  // retrieving Data from Local Storage
  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      // Remove the default exchange select value
      const { defaultExchange, ...filteredFormData } = parsedFormData;

      setOptionalFormData(filteredFormData);
    }
  }, [isReset]);

  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      if (parsedFormData["defaultExchange"] !== undefined) {
        dispatch(
          setDefaultExchange({
            name: "defaultExchange",
            checked: parsedFormData["defaultExchange"],
          })
        );
      }
      if (parsedFormData["defaultExchangeSelect"] !== undefined) {
        dispatch(
          setDefaultExchangeSelect({
            name: "defaultExchangeSelect",
            value: parsedFormData["defaultExchangeSelect"],
          })
        );
      }
      if (parsedFormData["customExchangeInput"] !== undefined) {
        dispatch(
          setCustomExchangeInput({
            name: "customExchangeInput",
            value: parsedFormData["customExchangeInput"],
          })
        );
      }
    }
  }, [isReset]);

  // Event handler for toggle switches
  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    if (name === "defaultExchange") {
      const newToggleValue = !defaultExchange;
      dispatch(setDefaultExchange({ name: name, checked: newToggleValue }));
    }

    const updatedFormData = {
      ...localStorageData,
      [name]: checked,
    };

    dispatch(setResetPayment(true));
    // Store the updated object in local storage
    localStorage.setItem("formData", JSON.stringify(updatedFormData));
    setOptionalFormData({ ...OptionalFormData, [name]: checked });
  };

  const handlePrevious = () => {
    setTab("tab1");
    setStepper(0);
    scrollToTop();
  };

  const stepUpdate2 = async () => {
    if (isConnected == false) {
      // alert("Please ReConnect Your Wallet");
      toast(
        <div>
          <p style={{ color: "white", fontWeight: 500 }}>
            Please Connect Your Wallet First
          </p>
        </div>,
        {
          autoClose: 10000,
          style: {
            background: "#45d09ae5",
          },
          progressClassName: "teal-toast-progress-bar",
        }
      );
      return;
    }

    if (!validateForm()) return;
    try {
      const response = await axios.put(
        "https://trendifytokens.io/api/files/updatetokendata",
        {
          tokenname: tokenName,
          walletaddress: walletAddress,
          id: ID,
          defaultexchange: defaultExchange,
          defaultexchangeselect: defaultExchange ? defaultExchangeSelect : "",
          routeraddress:
            defaultExchange &&
            defaultExchangeSelect !== "" &&
            defaultExchangeSelect !== "Custom exchange"
              ? selectedOptionData?.Routeraddress
              : "",
          customexchangeinput:
            defaultExchange && defaultExchangeSelect === "Custom exchange"
              ? customExchangeInput
              : "",
          antibotcooldown: defaultExchange
            ? OptionalFormData.antibotCooldown
            : false,
          antibotcooldowninput:
            defaultExchange &&
            OptionalFormData.antibotCooldown &&
            OptionalFormData.antibotCoolDownTime == "Seconds"
              ? OptionalFormData.antibotCooldownInput
              : OptionalFormData.antibotCoolDownTime == "Minutes"
              ? OptionalFormData.antibotCooldownInput * 60
              : OptionalFormData.antibotCoolDownTime == "Hours"
              ? OptionalFormData.antibotCooldownInput * 3600
              : "",
          enbletradingfunction: defaultExchange
            ? OptionalFormData.enbletradingFunction
            : false,
          maxamountperwallet: OptionalFormData.maxamountperWallet,
          maxamountperwalletinput: OptionalFormData.maxamountperWallet
            ? OptionalFormData.maxamountperWalletInput?.replace(/\s/g, "") * 10
            : "",
          maxtransactionlimit: OptionalFormData.maxTransactionLimit,
          maxtransactionlimitinput:
            defaultExchange == false && OptionalFormData.maxTransactionLimit
              ? OptionalFormData.maxTransactionLimitInput?.replace(/\s/g, "") *
                10
              : "",
          onbuyingcheck:
            defaultExchange && OptionalFormData.maxTransactionLimit
              ? OptionalFormData.onBuyingCheck
              : false,
          onbuyingcheckinput:
            defaultExchange &&
            OptionalFormData.maxTransactionLimit &&
            OptionalFormData.onBuyingCheck
              ? OptionalFormData.onBuyingCheckInput?.replace(/\s/g, "") * 10
              : "",
          onsellingcheck:
            defaultExchange && OptionalFormData.maxTransactionLimit
              ? OptionalFormData.onSellingCheck
              : false,
          onsellingcheckinput:
            defaultExchange &&
            OptionalFormData.maxTransactionLimit &&
            OptionalFormData.onSellingCheck
              ? OptionalFormData.onSellingCheckInput?.replace(/\s/g, "") * 10
              : "",
          ontransferringcheck:
            defaultExchange && OptionalFormData.maxTransactionLimit
              ? OptionalFormData.onTransferringCheck
              : false,
          ontransferringcheckinput:
            defaultExchange &&
            OptionalFormData.maxTransactionLimit &&
            OptionalFormData.onTransferringCheck
              ? OptionalFormData.onTransferringCheckinput?.replace(/\s/g, "") *
                10
              : "",
          pausable: OptionalFormData.pausable,
          blacklist: OptionalFormData.blacklist,
          foregintokenrecovery: OptionalFormData.foregintokenRecovery,
          permit: OptionalFormData.permit,
        }
      );
      // console.log("Default Exchange Form After POST:", OptionalFormData);
      if (response?.data?.status === true) {
        // console.log("Default Exchange Response:", response);
        dispatch(setOptionalApi());
        setTab("tab3");
        setStepper(2);
        scrollToTop();
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("Default Exchange Form Data outside:", OptionalFormData);

  // Reset Feature
  const [showOuterModal, setShowOuterModal] = useState(false);

  const handleOuterModalOpen = () => setShowOuterModal(true);

  const handleOuterModalClose = () => setShowOuterModal(false);

  // Function for handling refresh
  const handleReset = () => {
    // setIsReset(!isReset);
    // if (localStorageData) {
    //   const resetData = {
    //     ...localStorageData,
    //     defaultExchange: false,
    //     defaultExchangeSelect: "Elk Finance (Polygon Mumbai)",
    //     customExchangeInput: "",
    //     antibotCooldown: false,
    //     antibotCooldownInput: "",
    //     antibotCoolDownTime: "Seconds",
    //     enbletradingFunction: false,
    //     maxamountperWallet: false,
    //     maxamountperWalletInput: "",
    //     maxTransactionLimit: false,
    //     maxTransactionLimitInput: "",
    //     onBuyingCheck: false,
    //     onBuyingCheckInput: "",
    //     onSellingCheck: false,
    //     onSellingCheckInput: "",
    //     onTransferringCheck: false,
    //     onTransferringCheckinput: "",
    //     pausable: false,
    //     blacklist: false,
    //     foregintokenRecovery: false,
    //     permit: false,
    //   };
    //   localStorage.setItem("formData", JSON.stringify(resetData));
    // }
    resetAll();
    handleOuterModalClose();
  };

  return (
    <div
      className="rounded mx-auto p-4 custom-container"
      // style={{ padding: "2rem", width: "95%" }}
    >
      <Form>
        <div className="d-flex justify-content-between align-items-center">
          <p
            className="zeroMargin"
            style={{ color: "#45d09a", fontWeight: 500 }}
          >
            Default Exchange
          </p>
          <div>
            <Button
              className="bg-transparent border-0"
              onClick={handleOuterModalOpen}
            >
              <img src={RefreshIcon} alt="refresh" style={{ width: "1rem" }} />
            </Button>
            <RefreshModal
              showOuterModal={showOuterModal}
              handleOuterModalClose={handleOuterModalClose}
              handleReset={handleReset}
            />
          </div>
        </div>
        <hr style={{ color: "#9B9797" }} />
        <Row className="mt-0">
          {/* Default Exchange Toggle Button and dropdown UI */}
          <div>
            <Col md={4}>
              <div className="toggle-switch-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    name={"defaultExchange"}
                    id={"Default Exchange"}
                    checked={defaultExchange}
                    onChange={(e) => handleToggleChange(e)}
                  />
                  <span className="slider" />
                </label>
                <label
                  htmlFor={"Default Exchange"}
                  style={{ color: "#45d09a" }}
                >
                  {"Default Exchange"}
                </label>
              </div>
              <div style={{ color: "#45d09a" }}>
                Choose a default exchange (V2 only) where you want to add
                liquidity. This will allow for more precise configuration and
                more available features.
              </div>
            </Col>
            <Col md={12}>
              {defaultExchange && (
                <div>
                  {/* First Form Select which has Option value polygon mumbai and
                  custom exchange and its input */}
                  <Row className="mt-3">
                    <Col sm={4} className="my-2">
                      <Form.Select
                        style={{
                          color: "#FFF",
                          borderColor: "#45D09A",
                          backgroundColor: "#45D09A33",
                          boxShadow: "none",
                        }}
                        className="bg-transparent placeholderColor"
                        as="select"
                        id="InputText"
                        name="defaultExchangeSelect"
                        value={defaultExchangeSelect}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="" className="bg-dark">
                          Select
                        </option>
                        {Options?.map((option, index) => (
                          <option
                            key={index}
                            value={option?.name}
                            className="bg-dark"
                          >
                            {option?.name}
                          </option>
                        ))}
                      </Form.Select>
                      <div
                        className={`mt-2 ${
                          defaultExchangeSelect == "" ? "d-block" : "d-none"
                        }`}
                        style={{ color: "red" }}
                      >
                        {errors.defaultExchangeSelect}
                      </div>
                      {selectedOptionData &&
                        selectedOptionData.name !== "Custom exchange" &&
                        defaultExchangeSelect !== "" && (
                          <div
                            style={{
                              color: "#F1F1F1",
                              background: "#353535",
                            }}
                            className="my-4 d-flex flex-column flex-md-row align-items-center gap-3 px-3 py-2 rounded-2"
                          >
                            {/* Left Side */}
                            <div className="d-flex flex-column flex-md-row align-items-center gap-2">
                              <div>
                                <img src={InfoIcon} alt="infoIcon" />
                              </div>
                              <div>
                                <p className="fw-bold">
                                  {selectedOptionData.name}
                                </p>
                                <p className="mt-2">
                                  To ensure that you have selected the correct
                                  DEX for your token, we recommend visiting its
                                  page.
                                </p>
                              </div>
                            </div>
                            {/* Right Side */}
                            <div className="d-flex flex-md-column flex-lg-row align-items-center gap-3">
                              <div>
                                <a
                                  href={selectedOptionData.Dexlink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src={GlobalIcon} alt="Global" />
                                </a>
                              </div>
                              <div>
                                <a
                                  href={selectedOptionData.explorerlink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src={ExploreIcon} alt="Explore" />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      {selectedOptionData &&
                        selectedOptionData.name === "Custom exchange" && (
                          <Input
                            Label={"Custom exchange"}
                            Placeholder={"RouterV2 address"}
                            Description={"Enter valid RouterV2 address"}
                            Name={"customExchangeInput"}
                            onChange={(e) => handleInputChange(e)}
                            value={customExchangeInput}
                            margin="mt-3"
                            CustomInputError={errors.customExchangeInput}
                          />
                        )}
                    </Col>
                    {/* Anti Bot CoolDown Component */}
                    <CooldownToggle
                      Label="Anti-bot cooldown"
                      Description="After buying or selling address will be temporarily frozen, during which time it can't do any transfers from it and to it. However token scanners may show your token as honeypot if they can't detect this feature correctly."
                      InputDescription="0 seconds (disabled) ≤ Anti-bot cooldown ≤ 12 hours"
                      Placeholder1="0"
                      Placeholder2="Seconds"
                      Options={["Seconds", "Minutes", "Hours"]}
                      Error={errors.antibotCooldownInput}
                      toggleName="antibotCooldown"
                      checked={OptionalFormData.antibotCooldown}
                      toggleOnChange={(e) => handleToggleChange(e)}
                      inputName="antibotCooldownInput"
                      inputValue={OptionalFormData.antibotCooldownInput}
                      inputOnChange={(e) => handleInputChange(e)}
                      antibotCoolDownTimeName="antibotCoolDownTime"
                      antibotCoolDownTimeValue={
                        OptionalFormData.antibotCoolDownTime
                      }
                      antibotCoolDownTimeOnChange={(e) => handleInputChange(e)}
                    />
                    <Switch
                      Label="Enable Trading function"
                      Description="This feature prevents trading for all addresses (except excluded) after adding liquidity until the enableTrading() function is called by the owner."
                      Placeholder="Max Supply"
                      Error="Initial supply<= Max supply <= 9 999 999 999 999 999.9"
                      Name={"enbletradingFunction"}
                      checked={OptionalFormData.enbletradingFunction}
                      onChange={(e) => handleToggleChange(e)}
                    />
                  </Row>
                </div>
              )}
            </Col>
          </div>

          {/* <SwitchBoxNDropdown
            className="titles"
            Label="Default Exchange"
            Description="Choose a default exchange (V2 only) where you want to add liquidity. This will allow for more precise configuration and more available features."
            Placeholder="Select"
            Options={["Elk Finance (Polygon Mumbai)", "Custom exchange"]}
          /> */}
        </Row>

        {/* Limits Section Starts From Here */}
        <p className="titles">Limits</p>
        <hr style={{ color: "#9B9797" }} />
        {/* Max amount per wallet Toggle Switch UI */}
        <Row className="mt-3">
          <Col md={4}>
            {/* <Form.Check
              className="mt-3"
              style={{ color: "#F1F1F1" }}
              type="switch"
              id="Max amount per wallet"
              name="maxamountperWallet"
              checked={OptionalFormData.maxamountperWallet}
              onChange={(e) => handleToggleChange(e)}
              label="Max amount per wallet"
            /> */}
            <div className="toggle-switch-container">
              <label className="switch">
                <input
                  type="checkbox"
                  name={"maxamountperWallet"}
                  id={"Max amount per wallet"}
                  checked={OptionalFormData.maxamountperWallet}
                  onChange={(e) => handleToggleChange(e)}
                />
                <span className="slider" />
              </label>
              <label
                htmlFor={"Max amount per wallet"}
                style={{ color: "#45d09a" }}
              >
                {"Max amount per wallet"}
              </label>
            </div>
            <div style={{ color: "#45d09a" }}>
              Limits amount of tokens each address (except excluded) can hold.
            </div>

            {OptionalFormData.maxamountperWallet && (
              <div>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    className="bg-transparent mt-2 placeholderColor"
                    style={{
                      color: "#FFF",
                      borderColor: "#45D09A",
                      backgroundColor: "#45D09A33",
                      boxShadow: "none",
                    }}
                    placeholder={"Max wallet amount"}
                    id="InputText"
                    name="maxamountperWalletInput"
                    onChange={(e) => handleInputChange(e)}
                    value={OptionalFormData.maxamountperWalletInput}
                  />
                  <p
                    style={{
                      position: "absolute",
                      top: "0.5rem",
                      right: "0.5rem",
                      color: "#45d09a",
                    }}
                  >
                    {truncateText(tokenSymbol, 6)}
                  </p>
                </div>
                <div
                  style={{
                    color: errors.maxamountperWalletInput ? "red" : "#45d09a",
                  }}
                  className="mt-3"
                >
                  {
                    "0.1% of initial supply<= Max wallet amount<= Initial supply"
                  }
                </div>
              </div>
            )}
          </Col>
          {/* <SwitchInput
            className="titles"
            Label="Max amount per wallet"
            Description="Limits amount of tokens each address (except excluded) can hold."
            Placeholder="Max wallet amount"
            Error="0.1% of initial supply<= Max wallet amount<= Initial supply"
            Name="maxAmountperWallet"
            sliceName={"optional"}
            InputName="maxAmountperWalletInput"
            InputOnChange={handleInputChange}
            Inputvalue={OptionalFormData.maxAmountperWalletInput}
          /> */}

          {/* Max transaction limit Toggle Switch UI */}
          <div>
            <Col md={4}>
              {/* <Form.Check
                style={{ color: "#F1F1F1", fontWeight: "500" }}
                type="switch"
                name="maxTransactionLimit"
                id="Max transaction limit"
                checked={OptionalFormData.maxTransactionLimit}
                onChange={(e) => handleToggleChange(e)}
                label={"Max transaction limit"}
              /> */}
              <div className="toggle-switch-container my-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    name={"maxTransactionLimit"}
                    id={"Max transaction limit"}
                    checked={OptionalFormData.maxTransactionLimit}
                    onChange={(e) => handleToggleChange(e)}
                  />
                  <span className="slider" />
                </label>
                <label
                  htmlFor={"Max transaction limit"}
                  style={{ color: "#45d09a" }}
                >
                  {"Max transaction limit"}
                </label>
              </div>
              <div style={{ color: "#45d09a" }}>
                Limits amount of tokens each address (except excluded) can
                transfer in one transaction.
              </div>
            </Col>
            {/* Displaying SwitchInput Only when maxTransactionLimit is true and defaultExchange is true */}
            {OptionalFormData.maxTransactionLimit === true &&
            defaultExchange === true ? (
              <SwitchCheckboxInput
                Placeholder={"0"}
                Error={Error}
                InputDescription1={
                  "0.05% of initial supply<= Max buy limit<= Initial supply"
                }
                InputDescription2={
                  "0.05% of initial supply<= Max sell limit<= Initial supply"
                }
                InputDescription3={
                  "0.05% of initial supply<= Max transfer limit<= Initial supply"
                }
                label1={"On Buying"}
                label2={"On Selling"}
                label3={"On Transferring"}
                labelDescription1={"Specify the limit on buying."}
                labelDescription2={"Specify the limit on Selling."}
                labelDescription3={"Specify the limit on transferring."}
                labelName1={"onBuyingCheck"}
                labelName2={"onSellingCheck"}
                labelName3={"onTransferringCheck"}
                toggleValue1={OptionalFormData.onBuyingCheck}
                toggleValue2={OptionalFormData.onSellingCheck}
                toggleValue3={OptionalFormData.onTransferringCheck}
                toggleOnchange={(e) => handleToggleChange(e)}
                InputName1={"onBuyingCheckInput"}
                InputName2={"onSellingCheckInput"}
                InputName3={"onTransferringCheckinput"}
                InputValue1={OptionalFormData.onBuyingCheckInput}
                InputValue2={OptionalFormData.onSellingCheckInput}
                InputValue3={OptionalFormData.onTransferringCheckinput}
                InputOnChange={(e) => {
                  handleInputChange(e);
                }}
                onBuyError={errors.onBuyingCheckInput}
                onSellingError={errors.onSellingCheckInput}
                ontransferringError={errors.onTransferringCheckinput}
              />
            ) : (
              OptionalFormData.maxTransactionLimit === true && (
                <Col md={4}>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      className="bg-transparent mt-2 placeholderColor"
                      style={{
                        color: "#FFF",
                        borderColor: "#45D09A",
                        backgroundColor: "#45D09A33",
                        boxShadow: "none",
                      }}
                      placeholder={"Max transaction limit"}
                      id="InputText"
                      name="maxTransactionLimitInput"
                      onChange={(e) => handleInputChange(e)}
                      value={OptionalFormData.maxTransactionLimitInput}
                    />
                    <p
                      style={{
                        position: "absolute",
                        top: "0.5rem",
                        right: "0.5rem",
                        color: "#45d09a",
                      }}
                    >
                      {truncateText(tokenSymbol, 6)}
                    </p>
                  </div>
                  <div
                    style={{
                      color: errors.maxTransactionLimitInput
                        ? "red"
                        : "#45d09a",
                    }}
                    className="mt-3"
                  >
                    {
                      "0.05% of initial supply<= Max transaction limit <= Initial supply"
                    }
                  </div>
                </Col>
              )
            )}
          </div>

          {/* <SwitchCheckBox
            className="titles"
            Label="Max transaction limit"
            Description="Limits amount of tokens each address (except excluded) can transfer in one transaction."
          /> */}
        </Row>

        {/* Others Section Starts From Here */}

        <p className="titles">Others</p>
        <hr style={{ color: "#9B9797" }} />
        <Row className="my-3">
          <Switch
            className="titles"
            Label="Pausable"
            Description="Pausable allows owner to globally freeze the entire token contract - no one will be able to move their tokens, including owner."
            Placeholder="Max Supply"
            Error="Initial supply<= Max supply <= 9 999 999 999 999 999.9"
            Name={"pausable"}
            checked={OptionalFormData.pausable}
            onChange={(e) => handleToggleChange(e)}
          />
          <Switch
            className="titles"
            Label="Blacklist"
            Description="Adds a blacklist that owner can use to freeze specific addresses, disallowing any transactions to and from them until they are unblacklisted."
            Placeholder="Max Supply"
            Error="Initial supply<= Max supply <= 9 999 999 999 999 999.9"
            Name={"blacklist"}
            checked={OptionalFormData.blacklist}
            onChange={(e) => handleToggleChange(e)}
          />
          <Switch
            className="titles"
            Label="Foreign token recovery"
            Description="Utility function to recover other ERC-20 tokens that were sent to your token contract."
            Placeholder="Max Supply"
            Error="Initial supply<= Max supply <= 9 999 999 999 999 999.9"
            Name={"foregintokenRecovery"}
            checked={OptionalFormData.foregintokenRecovery}
            onChange={(e) => handleToggleChange(e)}
          />
        </Row>
        <Col md={4}>
          <Switch
            Label="Permit"
            Description="ERC-2612 Permit Extension - Utility function for signing gasless approvals through third-party smart contracts."
            Name={"permit"}
            checked={OptionalFormData.permit}
            onChange={(e) => handleToggleChange(e)}
          />
        </Col>
        <div className="d-flex flex-column flex-md-row align-items-center">
          <Col md={3} className="text-center mt-5 mx-auto">
            <button
              onClick={() => handlePrevious()}
              className="w-100 border-color"
              type="button"
            >
              Previous
            </button>
          </Col>
          <Col md={3} className="text-center mt-5 mx-auto">
            {/* <Button
              onClick={() => stepUpdate2()}
              className="w-100 bg-btn-color border-0"
              style={{ borderRadius: "4px" }}
            >
              Save & Next
            </Button> */}
            <button
              onClick={() => stepUpdate2()}
              className="w-100 save-next-btn mx-auto"
              type="button"
            >
              Save & Next
            </button>
          </Col>
        </div>
      </Form>
    </div>
  );
}

export default DefaultExchange;
