import { Button, Col } from "react-bootstrap";
import CopyIcon from "../Images/Logos/copy.svg";
import { useNavigate } from "react-router";
const TransactionSent = () => {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate(-1);
  };
  return (
    <>
      <div
        style={{
          height: "89vh",
          background: "linear-gradient(180deg, #31A2B1 0%, #16C7A6 100%)",
        }}
      >
        {/* Main Container */}
        <Col className="mx-auto  text-center py-4">
          {/* Upper */}
          <div>
            <div className="loader"></div>
            <p
              className="my-1"
              style={{ color: "#0AE7FE", fontWeight: 700, fontSize: "20px" }}
            >
              Transaction Sent
            </p>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <p
                style={{ color: "#F1F1F1", fontWeight: 500, margin: "1rem 0" }}
              >
                0x35c68bFd0b55793baD663D6b292C7b9144ab55B8
              </p>
              {/* <Button className="bg-transparent border-0"> */}
              <img src={CopyIcon} />
              {/* </Button> */}
            </div>
          </div>
          <p style={{ color: "#F1F1F1", fontWeight: 500, fontSize: "15px" }}>
            Your token will be created as soon as this transaction is
            confirmed...
          </p>
          <button
            style={{
              background: "#17171B",
              border: "none",
              borderRadius: "4px",
              width: "100px",
              color: "#0AE7FE",
              padding: "10px 20px",
              fontWeight: 500,
            }}
            onClick={handleNextClick}
          >
            NEXT
          </button>
        </Col>
      </div>
    </>
  );
};

export default TransactionSent;
