import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import CreateTokens from "./Screens/CreateTokens";
import Dashboard from "./Screens/Dashboard";
import TransactionSent from "./Screens/TransactionSent";
import { Provider } from "react-redux";
import Store from "./Store/Store";
import Manage from "./Screens/Manage";
import Home from "./Screens/Home";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Buffer } from "buffer";

// Web 3 Configuration

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider, useConfig, useAccount } from "wagmi";
import {
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  bscTestnet,
  baseSepolia,
  fantom,
} from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

// Ensure Buffer is available globally
if (!window.Buffer) {
  window.Buffer = Buffer;
}

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "58a22d2bc1c793fc31c117ad9ceba8d9";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [
  mainnet,
  arbitrum,
  optimism,
  polygon,
  bsc,
  avalanche,
  celo,
  base,
  fantom,
  bscTestnet,
  baseSepolia,
];
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  themeMode: "dark",
  allWallets: "SHOW",
  themeVariables: {
    "--w3m-accent": "#45D09A",
    "--w3m-border-radius-master": "1.5px",
    "--w3m-button-border-color": "none",
  },
  // enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // enableOnramp: true // Optional - false as default
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <Provider store={Store}>
        <ConnectionProvider endpoint="https://api.devnet.solana.com">
          <WalletProvider wallets={[]} autoConnect>
            <WalletModalProvider>
              <App />
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
        <ToastContainer />
      </Provider>
    </QueryClientProvider>
  </WagmiProvider>
);
