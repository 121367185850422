import React from "react";
import SmartContractStepper from "../Components/Forms/SmartContractStepper";
import OuterBg from "../Images/BgPattern/Background_Dark.png";
function CreateTokens() {
  return (
    <div
      style={{
        backgroundImage: `url(${OuterBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        width: "100%",
      }}
    >
      <SmartContractStepper />
    </div>
  );
}

export default CreateTokens;
