import { useState } from "react";
import useClickOutside from "../Hooks/Navigation/useClickOutside";
import { HiBars3, HiXMark } from "react-icons/hi2";
import { Link, NavLink } from "react-router-dom";
import logo from "../Images/Home/logo.png";

const TonTokenNavbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const sidebarRef = useClickOutside(isSidebarOpen, setIsSidebarOpen);
  const handleSidear = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <nav className="navbar-container py-3 flex sticky-top">
        <div>
          <Link to="/">
            <img src={logo} alt="logo" className="logo" />
          </Link>
        </div>
        {/* NAv for small Devices */}
        <div className="d-lg-none">
          <HiBars3 color="white" size="1.5rem" onClick={handleSidear} />
        </div>
        {isSidebarOpen && (
          <div className="overlay">
            <div className="navbar-sidebar" ref={sidebarRef}>
              <div className="d-flex justify-content-end m-3">
                <HiXMark size="1.5rem" onClick={handleSidear} />
              </div>
              <ul>
                <li>
                  <NavLink
                    to="#"
                    className={({ isActive }) =>
                      `link ${isActive ? "activeLink" : ""}`
                    }
                  >
                    Create Token
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="#"
                    className={({ isActive }) =>
                      `mx-2 link ${isActive ? "activeLink" : ""}`
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        )}

        {/* Nav for Large Devices */}
        <ul className="d-none d-lg-flex justify-content-md-between mt-3">
          <div className="d-flex align-items-center gap-md-3 gap-lg-3">
            <li>
              <NavLink
                to="#"
                className={({ isActive }) =>
                  `link ${isActive ? "activeLink" : ""}`
                }
              >
                Create Token
              </NavLink>
            </li>
            <li>
              <NavLink
                to="#"
                className={({ isActive }) =>
                  ` mx-3 link ${isActive ? "activeLink" : ""}`
                }
              >
                Dashboard
              </NavLink>
            </li>
          </div>
        </ul>
      </nav>
    </>
  );
};

export default TonTokenNavbar;
