// Function for formatting the spaces for initial supply
export const formatWithSpaces = (value) => {
  return value?.replace(/\D/g, "")?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

// Function for Formatting trucate the string for spl summary
export const truncateString = (str, maxLength = 6) => {
  if (typeof str !== "string") return str;

  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

// Clearing the Local Storage Data
export const clearLocalStorageAndRefresh = () => {
  try {
    const keysToRemove = ["splGeneral", "splMetaData", "splOptional"];

    keysToRemove.forEach((key) => localStorage.removeItem(key));

    window.location.reload();
  } catch (error) {
    console.error("Error clearing localStorage or refreshing the page:", error);
  }
};
