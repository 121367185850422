import React from "react";
import HeroImg from "../../Images/Home/herologo.svg";
import { Link } from "react-router-dom";
import Network1 from "../../Images/Home/network1.png";
import Network2 from "../../Images/Home/network2.png";
import Network3 from "../../Images/Home/network3.png";
import Network4 from "../../Images/Home/network4.png";
import Network5 from "../../Images/Home/network5.png";
import Network6 from "../../Images/Home/network6.png";
import Network7 from "../../Images/Home/network7.png";
import Network8 from "../../Images/Home/network8.png";
import Network9 from "../../Images/Home/network9.png";
import Network10 from "../../Images/Home/network10.png";
import Network11 from "../../Images/Home/network11.png";
import Network12 from "../../Images/Home/network12.png";
import Network13 from "../../Images/Home/network13.png";
import ImageRow from "./ImageRow";

const Hero = () => {
  const ercLogos = [
    Network1,
    Network2,
    Network3,
    Network4,
    Network5,
    Network6,
    Network7,
    Network8,
    Network9,
  ];

  const solanaLogos = [Network10, Network11];
  const tonLogos = [Network12, Network13];
  return (
    <section className="home-wrapper" id="home">
      <div className="d-flex flex-column gap-5 gap-md-1 flex-md-row justify-content-md-between align-items-md-center text-center text-md-start mx-3 mx-lg-3">
        <div>
          <p className="text-light mb-4">
            Introducing Multi Chain Token Creator
          </p>
          <h1 className="text-light hero-heading">
            Create a fully <br className="d-none d-md-block" />
            customizable token
          </h1>
          <p style={{ color: "#F1F1F1" }} className="mb-4">
            Create and manage your own unique cryptocurrency tokens across
            <br className="d-none d-lg-block" />
            multiple blockchain networks with ease using our innovative Multi
            Chain Token Creator.
          </p>

          {/* ERC 20 Logos */}
          <ImageRow logos={ercLogos} />

          <div className="my-4">
            <Link className="token-btn token-btn-fullwidth" to="/createtoken">
              Create ERC-20 Token
            </Link>
          </div>

          {/* Solana Token Creator Btn */}
          <div className="my-4">
            <p style={{ color: "#F1F1F1", fontSize: "20px" }}>
              Solana Token Creator
            </p>

            <p style={{ color: "#9B9797" }}>
              Create your token on Solana blockchain
            </p>

            {/* Solana Token Logos */}
            <ImageRow logos={solanaLogos} />

            <div className="my-2">
              <Link
                className="token-btn token-btn-fullwidth"
                to="https://solana.trendifytokens.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Create Solana Token
              </Link>
            </div>
          </div>

          {/* Ton Token Creator Btn */}
          <div className="mt-3">
            <p style={{ color: "#F1F1F1", fontSize: "20px" }}>
              Ton Token Creator
            </p>

            <p style={{ color: "#9B9797" }}>
              Create your token on Ton Blockchain
            </p>

            <ImageRow logos={tonLogos} />

            <div className="my-3">
              <Link
                className="token-btn token-btn-fullwidth"
                to="https://ton.trendifytokens.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Create Ton Token
              </Link>
            </div>
          </div>
        </div>
        <div>
          <img src={HeroImg} alt="hero" className="heroImg" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
