import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./SwitchCheckboxInput.css";

function SwitchCheckboxInputTaxes({
  Placeholder,
  Error,
  label1,
  label2,
  label3,
  labelDescription1,
  labelDescription2,
  labelDescription3,
  InputDescription1,
  InputDescription2,
  InputDescription3,
  labelName1,
  labelName2,
  labelName3,
  toggleValue1,
  toggleValue2,
  toggleValue3,
  toggleOnchange,
  InputName1,
  InputName2,
  InputName3,
  InputValue1,
  InputValue2,
  InputValue3,
  InputOnChange,
  liquidityBuyError,
  liquiditySellError,
  liquidityTransferError,
  dividendBuyError,
  dividendSellError,
  dividendTransferError,
  autoBurnBuyError,
  autoBurnSellError,
  autoBurnTransferError,
  walletBuyError,
  walletSellError,
  walletTransferError,
}) {
  return (
    <Row style={{ paddingTop: "15px", marginLeft: "0.5rem" }}>
      <Col sm={12} md={4}>
        <div style={{ marginRight: "10px" }}>
          {/* <input
            className="form-check-input bg-dark"
            type="checkbox"
            id={labelName1}
            name={labelName1}
            onChange={toggleOnchange}
            checked={toggleValue1}
          />
          <label
            className="form-check-label"
            htmlFor={labelName1}
            style={{ color: "#F1F1F1" }}
          >
            {label1}
          </label> */}
          <div className="checkbox-container">
            <input
              type="checkbox"
              id={labelName1}
              name={labelName1}
              onChange={toggleOnchange}
              checked={toggleValue1}
            />
            <label htmlFor={labelName1} style={{ color: "#45d09a" }}>
              {label1}
            </label>
          </div>
          <div style={{ color: "#45d09a", marginTop: "0.3rem" }}>
            {labelDescription1}
          </div>
          {toggleValue1 && (
            <div>
              <div style={{ position: "relative" }}>
                <Form.Control
                  className="mt-2 placeholderColor"
                  style={{
                    color: "#FFF",
                    borderColor: "#45D09A",
                    backgroundColor: "#45D09A33",
                    boxShadow: "none",
                  }}
                  placeholder={Placeholder}
                  id="InputText"
                  name={InputName1}
                  value={InputValue1}
                  onChange={InputOnChange}
                />
                <p
                  style={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                    color: "#45d09a",
                  }}
                >
                  %
                </p>
              </div>
              <div
                style={{
                  color:
                    liquidityBuyError ||
                    dividendBuyError ||
                    autoBurnBuyError ||
                    walletBuyError
                      ? "red"
                      : "#45d09a",
                }}
              >
                {InputDescription1}
              </div>
              <div style={{ color: "#737373" }} className="mt-3">
                {Error}
              </div>
            </div>
          )}
        </div>
      </Col>

      {/* Second Checkbox */}
      <Col sm={12} md={4}>
        <div style={{ marginRight: "10px" }}>
          {/* <input
            className="form-check-input bg-dark"
            type="checkbox"
            id={labelName2}
            name={labelName2}
            onChange={toggleOnchange}
            checked={toggleValue2}
          />
          <label
            className="form-check-label"
            htmlFor={labelName2}
            style={{ color: "#F1F1F1" }}
          >
            {label2}
          </label> */}
          <div className="checkbox-container">
            <input
              type="checkbox"
              id={labelName2}
              name={labelName2}
              onChange={toggleOnchange}
              checked={toggleValue2}
            />
            <label
              htmlFor={labelName2}
              style={{ color: "#45d09a", marginTop: "0.3rem" }}
            >
              {label2}
            </label>
          </div>
          <div style={{ color: "#45d09a" }}>{labelDescription2}</div>
          {toggleValue2 && (
            <div>
              <div style={{ position: "relative" }}>
                <Form.Control
                  className="mt-2 placeholderColor"
                  style={{
                    color: "#FFF",
                    borderColor: "#45D09A",
                    backgroundColor: "#45D09A33",
                    boxShadow: "none",
                  }}
                  placeholder={Placeholder}
                  id="InputText"
                  name={InputName2}
                  value={InputValue2}
                  onChange={InputOnChange}
                />
                <p
                  style={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                    color: "#45d09a",
                  }}
                >
                  %
                </p>
              </div>
              <div
                style={{
                  color:
                    liquiditySellError ||
                    dividendSellError ||
                    autoBurnSellError ||
                    walletSellError
                      ? "red"
                      : "#45d09a",
                }}
              >
                {InputDescription2}
              </div>
              <div style={{ color: "#737373" }} className="mt-3">
                {Error}
              </div>
            </div>
          )}
        </div>
      </Col>

      {/* Third Checkbox */}
      <Col sm={12} md={4}>
        <div style={{ marginRight: "10px" }}>
          {/* <input
            className="form-check-input bg-dark"
            type="checkbox"
            id={labelName3}
            name={labelName3}
            onChange={toggleOnchange}
            checked={toggleValue3}
          />
          <label
            className="form-check-label"
            htmlFor={labelName3}
            style={{ color: "#F1F1F1" }}
          >
            {label3}
          </label> */}
          <div className="checkbox-container">
            <input
              type="checkbox"
              id={labelName3}
              name={labelName3}
              onChange={toggleOnchange}
              checked={toggleValue3}
            />
            <label
              htmlFor={labelName3}
              style={{ color: "#45d09a", marginTop: "0.3rem" }}
            >
              {label3}
            </label>
          </div>
          <div style={{ color: "#45d09a" }}>{labelDescription3}</div>
          {toggleValue3 && (
            <div>
              <div style={{ position: "relative" }}>
                <Form.Control
                  className="mt-2 placeholderColor"
                  style={{
                    color: "#FFF",
                    borderColor: "#45D09A",
                    backgroundColor: "#45D09A33",
                    boxShadow: "none",
                  }}
                  placeholder={Placeholder}
                  id="InputText"
                  name={InputName3}
                  value={InputValue3}
                  onChange={InputOnChange}
                />
                <p
                  style={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                    color: "#45d09a",
                  }}
                >
                  %
                </p>
              </div>
              <div
                style={{
                  color:
                    liquidityTransferError ||
                    dividendTransferError ||
                    autoBurnTransferError ||
                    walletTransferError
                      ? "red"
                      : "#45d09a",
                }}
              >
                {InputDescription3}
              </div>
              <div style={{ color: "#737373" }} className="mt-3">
                {Error}
              </div>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default SwitchCheckboxInputTaxes;
