import React from "react";
import InputGroup from "./InputGroup";
import OwnerOnlyWrapper from "./OwnerOnlyWrapper/OwnerOnlyWrapper";
import InputHeader from "./InputHeader";

const Pausable = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Pausable</p>
      <InputHeader
        label="Pause  status"
        subHeading="paused( ) - Is the token paused right now?"
      />
      <p style={{ color: "#0AE7FE" }}>False (Not paused)</p>
      {/* Pause */}
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Pause"
          subHeading={
            "pause( ) - Pause token contract to disallow sending transactions"
          }
          ButtonLabel="Pause"
          margin="my-0"
        />
        <div className="mt-4">
          <InputGroup
            Label="Unpause"
            subHeading={"unpause( ) - Unpause token"}
            ButtonLabel="Unpause"
            margin="my-0"
          />
        </div>
      </OwnerOnlyWrapper>
    </div>
  );
};

export default Pausable;
