import { createSlice } from "@reduxjs/toolkit";

const ResetPaymentSlice = createSlice({
  name: "resetpayment",
  initialState: {
    resetPayment: false,
  },
  reducers: {
    setResetPayment: (state, action) => {
      state.resetPayment = action.payload;
    },
  },
});

export const { setResetPayment } = ResetPaymentSlice.actions;
export default ResetPaymentSlice.reducer;
