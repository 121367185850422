import Optimism from "../../Images/Home/Optimism.png";
import Mantle from "../../Images/Home/Mantle.png";
import Arbitrum from "../../Images/Home/Arbitrum.png";
import Celo from "../../Images/Home/Celo.png";
import Cronos from "../../Images/Home/Cronos.png";
import Ethereum from "../../Images/Home/Ethereum.png";
import Avalanche from "../../Images/Home/Avalanche.png";
import BNB from "../../Images/Home/BNB.png";
import Pulse from "../../Images/Home/Pulse.png";
import Evmos from "../../Images/Home/Evmos.png";
import Base from "../../Images/Home/Base.png";
import Fantom from "../../Images/Home/Fantom.png";
import Polygon from "../../Images/Home/Polygon.png";
import Linea from "../../Images/Home/Linea.png";
import Check from "../../Images/Home/tick.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PayByLogo from "../../Images/Home/paybytrendlogo.png";

const PricingCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="p-4 pricing-card">
      {/* card heading */}
      <div>
        <p className="pricing-card-title">{data?.pricingTitle}</p>
        <p className="pricing-card-price">{data?.pricing}</p>
      </div>
      {/* Card Feat */}
      <div className="my-4">
        {data?.pricingFeat?.map((item) => (
          <div className="d-flex mb-3 gap-2">
            <div>
              <img src={Check} alt="check" />
            </div>
            <div>
              <p className="text-light">{item?.feat}</p>
              <Link className="border-b">{item?.verifylink}</Link>
            </div>
          </div>
        ))}
      </div>
      <div
        className="d-flex flex-column gap-2"
        // style={{ marginTop: data.id == 3 ? "1.5rem" : "0rem" }}
      >
        {data.id === 1 && (
          <>
            <p className="text-light">Blockchains</p>
            <p style={{ color: "var(--home-text-gray)" }}>
              (Approximate each coin 100 USDC)
            </p>
          </>
        )}
      </div>
      {/* Card Networks */}
      <div className="my-4">
        {data?.networks?.map((item) => (
          <div className="flex mb-2">
            <div className="d-flex align-items-center gap-2">
              <div>
                <img src={item?.logo} alt="" />
              </div>
              <p style={{ color: "var(--home-text-gray)" }}>{item?.name}</p>
            </div>
            <div>
              <p className="text-light">{item?.price}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-auto">
        <button
          onClick={() => {
            if (data?.id === 1) {
              navigate(data?.navigationLink);
            } else {
              window.open(data?.navigationLink, "_blank");
            }
          }}
          className="text-center pricing-token-btn w-100 token-btn text-light border-0"
        >
          {data?.buttonLabel}
        </button>
      </div>
    </div>
  );
};

const Pricing = () => {
  // const fetchNetworkData = async () => {
  //   try {
  //     const response = await fetch(`https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?CMC_PRO_API_KEY=da7c0650-94d9-4607-baa2-7bc7acd6c4b7`
  //       {
  //         headers: {
  //           'X-CMC_PRO_API_KEY': 'da7c0650-94d9-4607-baa2-7bc7acd6c4b7',
  //         }
  //       }
  //     )
  //     const jsonResponse = await response.json();
  //     console.log(response);
  //     console.log("JSON" , jsonResponse);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   fetchNetworkData();
  // },[])
  const pricingCardData = [
    {
      id: 1,
      pricingTitle: "Mainnets - Custom",
      pricing: "~$100",
      pricingFeat: [
        {
          id: 1,
          feat: "All token features",
        },
        // {
        //   id: 2,
        //   feat: "Token owner dashboard",
        // },
        {
          id: 2,
          feat: "Support on Telegram group",
        },
        {
          id: 3,
          feat: "Choose from 9 Mainnets",
        },
        {
          id: 4,
          feat: "Custom prefix in token address",
        },
        // {
        //   id: 6,
        //   feat: "Verification with your label",
        //   verifylink: "Learn more about verification",
        // },
      ],
      networks: [
        {
          id: 1,
          logo: Ethereum,
          name: "Ethereum",
          price: "(100 USDC)",
        },
        {
          id: 2,
          logo: Optimism,
          name: "Optimism",
          price: "(100 USDC)",
        },
        {
          id: 3,
          logo: Polygon,
          name: "Polygon",
          price: "(100 USDC)",
        },
        {
          id: 4,
          logo: Avalanche,
          name: "Avalanche",
          price: "(100 USDC)",
        },
        {
          id: 5,
          logo: BNB,
          name: "BNB Smart Chain",
          price: "(100 USDC)",
        },
        {
          id: 6,
          logo: Arbitrum,
          name: "Arbitrum One",
          price: "(100 USDC)",
        },
        {
          id: 7,
          logo: Celo,
          name: "Celo",
          price: "(100 USDC)",
        },
        {
          id: 8,
          logo: Base,
          name: "Base",
          price: "(100 USDC)",
        },
        {
          id: 9,
          logo: Fantom,
          name: "Fantom",
          price: "(100 USDC)",
        },
      ],
      buttonLabel: "Create a Token",
      navigationLink: "/createtoken",
    },
    {
      id: 2,
      pricingTitle: "Solana",
      pricing: "~0.4 Sol",
      pricingFeat: [
        {
          id: 1,
          feat: "All token features",
        },
        // {
        //   id: 2,
        //   feat: "Token owner dashboard",
        // },
        {
          id: 3,
          feat: "Support on Telegram group",
        },
        // {
        //   id: 4,
        //   feat: "Choose from 11 Mainnets",
        // },
        {
          id: 5,
          feat: "Custom prefix in token address",
        },
        // {
        //   id: 6,
        //   feat: "Verification with SC label",
        //   verifylink: "Learn more about verification",
        // },
      ],
      // networks: [
      //   {
      //     id: 1,
      //     logo: Optimism,
      //     name: "Optimism",
      //     price: "0.0351 ETH",
      //   },
      //   {
      //     id: 2,
      //     logo: Mantle,
      //     name: "Mantle",
      //     price: "123.5 MNT",
      //   },
      //   {
      //     id: 3,
      //     logo: Arbitrum,
      //     name: "Arbitrum One",
      //     price: "0.0351 ETH",
      //   },
      //   {
      //     id: 4,
      //     logo: Core,
      //     name: "Core",
      //     price: "208 CORE",
      //   },
      //   {
      //     id: 5,
      //     logo: Cronos,
      //     name: "Cronos",
      //     price: "??? CRO",
      //   },
      //   {
      //     id: 6,
      //     logo: Ethereum,
      //     name: "Ethereum",
      //     price: "0.0351 ETH",
      //   },
      //   {
      //     id: 7,
      //     logo: Avalanche,
      //     name: "Avalanche",
      //     price: "2.21 AVAX",
      //   },
      //   {
      //     id: 8,
      //     logo: BNB,
      //     name: "BNB Smart Chain",
      //     price: "0.234 BNB",
      //   },
      //   {
      //     id: 9,
      //     logo: Pulse,
      //     name: "Pulse Chain",
      //     price: "975000 PLS",
      //   },
      //   {
      //     id: 10,
      //     logo: Evmos,
      //     name: "Evmos",
      //     price: "1235 EVMOS",
      //   },
      //   {
      //     id: 11,
      //     logo: Base,
      //     name: "Base",
      //     price: "0.0351 ETH",
      //   },
      //   {
      //     id: 12,
      //     logo: Fantom,
      //     name: "Fantom",
      //     price: "162.5 FTM",
      //   },
      //   {
      //     id: 13,
      //     logo: Polygon,
      //     name: "Polygon",
      //     price: "124.8 MATIC",
      //   },
      //   {
      //     id: 14,
      //     logo: Linea,
      //     name: "Linea",
      //     price: "0.0351 ETH",
      //   },
      // ],
      buttonLabel: "Create Solana Token",
      navigationLink: "https://solana.trendifytokens.io/",
    },
    {
      id: 3,
      pricingTitle: "Ton",
      pricing: "~15 Ton",
      pricingFeat: [
        {
          id: 1,
          feat: "All token features",
        },
        // {
        //   id: 2,
        //   feat: "Token owner dashboard",
        // },
        {
          id: 2,
          feat: "Support on Telegram group",
        },
        // {
        //   id: 3,
        //   feat: "Choose from 2 Testnets",
        // },
        {
          id: 4,
          feat: "Custom prefix in token address",
        },
        // {
        //   id: 6,
        //   feat: "Verification",
        // },
      ],
      // networks: [
      //   {
      //     id: 1,
      //     logo: Ethereum,
      //     name: "Goerli Testnet",
      //     price: "0 ETH",
      //   },
      //   {
      //     id: 2,
      //     logo: Avalanche,
      //     name: "Avalanche Testnet",
      //     price: "0 AVAX",
      //   },
      //   {
      //     id: 1,
      //     logo: BNB,
      //     name: "BSC Testnet",
      //     price: "0 BNB",
      //   },
      //   {
      //     id: 2,
      //     logo: Base,
      //     name: "Base Sepolia",
      //     price: "0 ETH",
      //   },
      //   {
      //     id: 4,
      //     logo: Fantom,
      //     name: "Fantom Testnet",
      //     price: "0 FTM",
      //   },
      //   {
      //     id: 5,
      //     logo: Polygon,
      //     name: "Polygon Mumbai ",
      //     price: "0 MATIC",
      //   },
      // ],
      buttonLabel: "Create Ton Token",
      navigationLink: "https://ton.trendifytokens.io/",
    },
  ];

  return (
    <section className="home-wrapper" id="pricing">
      {/* Main Container */}
      <div className="my-5 px-3 pricing-container">
        {/* Pricing Header */}
        <div className="text-center">
          <p style={{ color: "var(--home-text-color)" }}>Pricing</p>
          <p className="Pricing-heading">Simple and Transparent</p>
          <p style={{ color: "var(--home-text-gray)" }}>
            Choose the best plan for your needs. <br /> If you have any
            questions, feel free to{" "}
            <span style={{ color: "var(--home-text-color)" }}>contact us.</span>
          </p>
        </div>
        {/* Pricing card */}
        <div className="mt-5 d-flex flex-column  align-items-center justify-content-center">
          <div className="d-flex flex-wrap gap-4">
            {pricingCardData?.map((item) => (
              <PricingCard data={item} key={item.id} />
            ))}
          </div>
          <div>
            <img src={PayByLogo} alt={PayByLogo} className="payByLogo" />
          </div>
        </div>
        {/* Pricing Note */}
        <div className="text-center my-5">
          <p
            className="mb-3 mb-md-0"
            style={{ color: "var(--home-text-gray)" }}
          >
            Currency used for payments depends on the chosen blockchain.
          </p>
          <p
            className="mb-3 mb-md-0"
            style={{ color: "var(--home-text-gray)" }}
          >
            Payments for deployments and custom verification label are charged
            separately.
          </p>
          <p
            className="mb-3 mb-md-0"
            style={{ color: "var(--home-text-gray)" }}
          >
            Prices quoted in USD may not be accurate due to changes in
            cryptocurrency prices.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
