import { formatWithSpaces } from "./helperFunction";

// resuable function for more readablity
const validateSolanaAddress = (value) => {
  if (!value) {
    return "This field is required.";
  } else if (!/^[a-zA-Z0-9]{32,44}$/.test(value)) {
    return "Enter a valid Solana wallet address.";
  }
  return null;
};

const validateTokenField = (value) => {
  if (!value) {
    return "This field is required.";
  } else if (!/^[A-Za-z]+$/.test(value)) {
    return "Only alphabetic characters are allowed.";
  }
  return null;
};

// exporting the validation functions
export const validateField = (name, value, formData) => {
  const errors = {};
  switch (name) {
    case "mintAuthority":
      if (formData?.mintable) {
        errors[name] = validateSolanaAddress(value);
      }
      break;

    case "freezeAuthority":
      if (formData?.freezable) {
        errors[name] = validateSolanaAddress(value);
      }
      break;

    case "taxRate":
      if (formData?.transferTax) {
        if (!value) {
          errors[name] = "Tax rate is required.";
        } else if (!/^\d+$/.test(value)) {
          errors[name] = "Tax rate must be an integer.";
        } else {
          const rate = parseInt(value, 10);
          if (rate < 0 || rate > 25) {
            errors[name] = "Tax rate must be between 0 and 25.";
          }
        }
      }
      break;

    case "configAuthority":
    case "withdrawAuthority":
      if (formData?.transferTax) {
        errors[name] = validateSolanaAddress(value);
      }
      break;

    case "maxFeePerTxn":
      if (formData?.transferTax) {
        const splGeneralData = JSON.parse(localStorage.getItem("splGeneral"));
        const initialSupply = splGeneralData?.initialSupply
          ? parseInt(splGeneralData.initialSupply.replace(/\s/g, ""), 10)
          : 0; // Fallback to 0 if not found

        if (!value) {
          errors[name] = "Max fee per transaction is required.";
        } else {
          const maxFee = parseInt(value, 10);
          if (maxFee < 0) {
            errors[name] = "Max fee per transaction cannot be negative.";
          } else if (maxFee > initialSupply) {
            errors[
              name
            ] = `Max fee per transaction cannot exceed the initial supply ${initialSupply}.`;
          }
        }
      }
      break;

    default:
      break;
  }
  return errors[name];
};
// Validation function for Validating General Step
export const validateGeneralField = (name, value, formData) => {
  const errors = {};

  const maxSupplyLimit = 18400000000000000000 / 10 ** formData.decimals;

  const formattedMaxSupplyLimit = formatWithSpaces(maxSupplyLimit);
  switch (name) {
    case "tokenName":
      errors[name] = validateTokenField(value);
      break;

    case "tokenSymbol":
      errors[name] = validateTokenField(value);
      break;
    case "supplyRecipientInput":
      if (formData?.supplyRecipient) {
        errors[name] = validateSolanaAddress(value);
      }
      break;
    case "initialSupply":
      if (value < 1 || value > maxSupplyLimit) {
        errors[
          name
        ] = `Initial supply must be between 1 and ${formattedMaxSupplyLimit}`;
      }
      break;
    default:
      break;
  }
  return errors[name];
};
export const validateTonGeneralField = (name, value, formData) => {
  const errors = {};

  switch (name) {
    case "tokenName":
      errors[name] = validateTokenField(value);
      break;

    case "tokenSymbol":
      errors[name] = validateTokenField(value);
      break;

    default:
      break;
  }
  return errors[name];
};
