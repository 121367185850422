import React from "react";
import { Button, Col } from "react-bootstrap";

const SummaryFooter = ({ handlePrevious }) => {
  return (
    <>
      <div>
        {/* Checkmark
        <div className="checkbox-container flex-column flex-md-row align-items-start align-items-md-center">
          <input
            type="checkbox"
            id={"check"}
            name={"check"}
            onChange={handleCheckboxChange}
            checked={isCheckboxChecked}
          />
          <label htmlFor={"check"} style={{ color: "#E5B649" }}>
            I understand that some values are unchangeable after token creation.
          </label>
        </div> */}

        <div className="d-flex justify-content-center mt-3">
          <Col md={3} className="text-center mx-auto">
            <button
              onClick={() => handlePrevious()}
              className="w-100 border-color"
              type="button"
            >
              Previous
            </button>
          </Col>
          <Col md={3} className="text-center mx-auto">
            <Button
              style={{
                background: "linear-gradient(rgb(19 107 127), rgb(6 141 114))",
                border: "0px",
                width: "100%",
              }}
              //   disabled={!isCheckboxChecked}
              //   onClick={handleOpenModal}
            >
              Deploy
            </Button>
          </Col>
        </div>
      </div>
    </>
  );
};

export default SummaryFooter;
