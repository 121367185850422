import React from "react";

const ImageRow = ({ logos }) => {
  return (
    <>
      <div className="marquee">
        {logos.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`network-img-${index + 1}`}
            className="marquee-img"
          />
        ))}
      </div>
    </>
  );
};

export default ImageRow;
