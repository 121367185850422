import React from "react";
import { Col, FormLabel, Row } from "react-bootstrap";
import Input from "../../Input";
import PlusIcon from "../../../../Images/Logos/plus-icon.svg";
import MinusIcon from "../../../../Images/Logos/minus-icon.svg";
import { handleInitialSupplyKeyDown } from "../../../../Utils/keyValidation";

const General = ({
  formData,
  errors,
  onChange,
  formatWithSpaces,
  handleIncrement,
  handleDecrement,
}) => {
  return (
    <div>
      <Row className="mt-3">
        <Row>
          <Input
            Label="Token Name"
            Description="Max 50 character (Not editable Later)"
            Name="tokenName"
            Placeholder="Ex: TokenMoon"
            width={4}
            value={formData?.tokenName}
            onChange={onChange}
            Error={errors?.tokenName}
            maxLength={50}
          />

          <Input
            Label="Token Ticker"
            Description="Max 20 character (Not editable Later)"
            Placeholder="Ex: MTK"
            Name="tokenSymbol"
            width={4}
            value={formData?.tokenSymbol}
            onChange={onChange}
            Error={errors?.tokenSymbol}
            maxLength={20}
          />

          <Col md={4}>
            <div>
              <FormLabel
                className={`d-flex align-items-start`}
                style={{ color: "#F1F1F1" }}
              >
                Decimals
                <p style={{ color: "#F00" }}>*</p>
              </FormLabel>
              <div className="decimal-container">
                <button
                  type="button"
                  onClick={() => handleDecrement("decimals", 0)}
                >
                  <img src={MinusIcon} alt="MinusIcon" />
                </button>
                <div className="w-50 mx-auto text-center">
                  <p className="zeroMargin white-color">{formData?.decimals}</p>
                </div>
                <button
                  type="button"
                  onClick={() => handleIncrement("decimals", 9)}
                >
                  <img src={PlusIcon} alt="plusIcon" />
                </button>
              </div>
              <div>
                <p style={{ color: "#737373" }}>
                  0 &le; Decimals &le; 9 (Not editable later)
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Row>
      <Row className="mt-5">
        <Input
          Label="Tokens to Mint"
          Placeholder="Ex: 1000 000 Tokens"
          Description={`Number of Initial Tokens to mint and send to your wallet address`}
          Name="mintToken"
          width="4"
          value={formatWithSpaces(formData?.mintToken)}
          onChange={onChange}
          Error={errors?.mintToken}
          onKeyDown={(event) =>
            handleInitialSupplyKeyDown(event, 20, formData?.mintToken)
          }
        />
        <Input
          Label="Description"
          Placeholder="Enter Description"
          Description={`Optional Sentence explaining about your project`}
          Name="description"
          width="4"
          value={formData?.description}
          onChange={onChange}
          Error={errors?.description}
        />
      </Row>
    </div>
  );
};

export default General;
