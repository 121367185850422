import { React, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateSwitchInput } from "../../Store/Slices/GeneralSlice";
import { setResetPayment } from "../../Store/Slices/ResetPaymentSlice";
import "bootstrap/dist/css/bootstrap.min.css";
import { truncateText } from "../../Utils/helperFunction";

function SwitchInput({
  Description,
  Name,
  Label,
  Error,
  Placeholder,
  InputName,
  InputOnChange,
  Inputvalue,
  InputDescription,
  required,
  Error2,
  tokenSymbol,
  tokenSymbolValue,
}) {
  const dispatch = useDispatch();

  const switchValue = useSelector((state) => state.general[Name]);

  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      if (parsedFormData[Name] !== undefined) {
        dispatch(
          updateSwitchInput({ name: Name, checked: parsedFormData[Name] })
        );
      }
    }
  }, [dispatch, Name]);

  const handleSwitchChange = () => {
    const newSwitchValue = !switchValue;
    dispatch(updateSwitchInput({ name: Name, checked: newSwitchValue }));

    dispatch(setResetPayment(true));

    // Update the local storage
    const storedFormData = localStorage.getItem("formData");
    let formData = storedFormData ? JSON.parse(storedFormData) : {};
    formData[Name] = newSwitchValue;
    localStorage.setItem("formData", JSON.stringify(formData));
  };

  return (
    <Col md={5}>
      {/* <Form.Check
        className="mt-3"
        style={{ color: "#F1F1F1" }}
        type="switch"
        id={Name}
        checked={switchValue}
        onChange={handleSwitchChange}
        label={Label}
      />
      <div style={{ color: "#737373" }} className="mt-3">
        {Description}
      </div> */}

      <div className="toggle-switch-container">
        <div>
          <label className="switch">
            <input
              type="checkbox"
              name={Name}
              id={Label}
              checked={switchValue}
              onChange={handleSwitchChange}
            />
            <span className="slider" />
          </label>
        </div>

        <label
          htmlFor={Label}
          className="d-flex algn-items-start"
          style={{ color: "#45d09a" }}
        >
          {Label}
          {required && <p style={{ color: "#F00" }}>*</p>}
        </label>
      </div>
      <div style={{ color: "#45d09a" }} className="mt-1">
        {Description}
      </div>
      <div style={{ color: "red" }} className="mt-2">
        {Error2}
      </div>

      {switchValue && (
        <div>
          <div style={{ position: tokenSymbol ? "relative" : "" }}>
            <Form.Control
              className="mt-2 placeholderColor"
              style={{
                color: "#FFF",
                borderColor: "#45D09A",
                backgroundColor: "#45D09A33",
                boxShadow: "none",
              }}
              placeholder={Placeholder}
              id="InputText"
              name={InputName}
              onChange={InputOnChange}
              value={Inputvalue}
            />
            {tokenSymbol && (
              <p
                style={{
                  position: "absolute",
                  top: "0.5rem",
                  right: "0.5rem",
                  color: "#45d09a",
                }}
              >
                {truncateText(tokenSymbolValue, 6)}
              </p>
            )}
          </div>
          <div style={{ color: "#45d09a" }}>{InputDescription}</div>

          <div style={{ color: "red" }} className="mt-3">
            {Error}
          </div>
        </div>
      )}
    </Col>
  );
}

export default SwitchInput;
