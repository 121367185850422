import React from "react";
import "./RefreshModal.css";
const RefreshModal = ({
  showOuterModal,
  handleOuterModalClose,
  handleReset,
}) => {
  return (
    <>
      {showOuterModal && (
        <div className="modal-overlay" onClick={handleOuterModalClose}>
          <div className="outer-modal" onClick={(e) => e.stopPropagation()}>
            <p className="zeroMargin refresh-modal-header">Are you sure?</p>

            <p className="refresh-modal-para">
              You are going to reset all the values in this form to their
              defaults.
            </p>

            <div className="flex">
              <button
                className="button cancel-btn"
                onClick={handleOuterModalClose}
                type="button"
              >
                Cancel
              </button>
              <button
                className="button reset-btn"
                onClick={handleReset}
                type="button"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RefreshModal;
