import React from "react";
import StaticsImg from "../../Images/Home/Statistics.png";

const Statistics = () => {
  return (
    <section className="home-wrapper" id="statistics">
      {/* Main Container */}
      <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center px-3 px-lg-0 statistics-container">
        {/* Left Part */}
        <div className="statistics-left-container">
          <div className="text-center text-md-start">
            <p style={{ color: "var(--home-text-color)" }}>Statistics</p>
            <p className="statistics-heading">Now we know that</p>
          </div>
          <p
            className="text-center text-md-start"
            style={{ color: "var(--home-text-gray)" }}
          >
            what matter most is the freedom to customize features,{" "}
            <br className="d-none d-md-block" />
            gas-saving functions, and the security for the owner and for all
            token holders.
          </p>
          {/* Left Lower */}
          <div className="mt-5">
            {/* <div className="d-flex flex-column gap-4 flex-md-row justify-content-xl-between mb-4">
              <div>
                <p className="statistics-title">14</p>
                <p style={{ color: "var(--home-text-gray)" }}>
                  Crucial features to choose <br /> from as an owner
                </p>
              </div>
              <div>
                <p className="statistics-title">19</p>
                <p style={{ color: "var(--home-text-gray)" }}>
                  Blockchain supported so far
                </p>
              </div>
            </div>
            <div className="d-flex flex-column gap-4 flex-md-row justify-content-xl-between">
              <div>
                <p className="statistics-title">400+</p>
                <p style={{ color: "var(--home-text-gray)" }}>
                  Community members on <br /> Telegram group
                </p>
              </div>
              <div>
                <p className="statistics-title">1000+</p>
                <p style={{ color: "var(--home-text-gray)" }}>
                  Tokens created on Mainnets
                </p>
              </div>
            </div> */}
            <div className="row mb-2">
              <div className="col">
                <p className="statistics-title">14</p>
                <p style={{ color: "var(--home-text-gray)" }}>
                  Crucial features to choose <br /> from as an owner
                </p>
              </div>
              <div className="col">
                <p className="statistics-title">9</p>
                <p style={{ color: "var(--home-text-gray)" }}>
                  Blockchain supported so far
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="statistics-title">400+</p>
                <p style={{ color: "var(--home-text-gray)" }}>
                  Community members on <br /> Telegram group
                </p>
              </div>
              <div className="col">
                <p className="statistics-title">1000+</p>
                <p style={{ color: "var(--home-text-gray)" }}>
                  Tokens created on Mainnets
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Right Part */}
        <div>
          <img src={StaticsImg} alt="staticsimg" className="statics-img" />
        </div>
      </div>
    </section>
  );
};

export default Statistics;
