export const Routeraddress = {
  1: [
    {
      name: "Uniswap V2",
      Routeraddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      Dexlink: "https://app.uniswap.org/swap",
      explorerlink:
        "https://etherscan.io/address/0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    },
    {
      name: "ShibhaSwap",
      Routeraddress: "0x03f7724180AA6b939894B5Ca4314783B0b36b329",
      Dexlink: "https://shibaswap.com/",
      explorerlink:
        "https://etherscan.io/address/0x03f7724180AA6b939894B5Ca4314783B0b36b329",
    },
    {
      name: "ShibhaSwap V2 (Ethereum)",
      Routeraddress: "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
      Dexlink: "https://www.sushi.com/swap",
      explorerlink:
        "https://etherscan.io/address/0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
    },
    {
      name: "ShibhaSwap V2 (Ethereum)",
      Routeraddress: "0x5f509a3C3F16dF2Fba7bF84dEE1eFbce6BB85587",
      Dexlink: "https://apeswap.finance/",
      explorerlink:
        "https://etherscan.io/address/0x5f509a3C3F16dF2Fba7bF84dEE1eFbce6BB85587",
    },
    {
      name: "Custom exchange",
    },
  ],
  137: [
    {
      name: "QuickSwap V2",
      Routeraddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
      Dexlink: "https://quickswap.exchange/#/",
      explorerlink:
        "https://polygonscan.com/address/0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    },
    {
      name: "ApeSwap (Polygon)",
      Routeraddress: "0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607",
      Dexlink: "https://apeswap.finance/",
      explorerlink:
        "https://polygonscan.com/address/0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607",
    },
    {
      name: "SushiSwap V2 (Polygon)",
      Routeraddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      Dexlink: "https://www.sushi.com/swap",
      explorerlink:
        "https://polygonscan.com/address/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    },
    {
      name: "MM Finance V2 (Polygon)",
      Routeraddress: "0x7E5E5957De93D00c352dF75159FbC37d5935f8bF",
      Dexlink: "https://polymm.finance/",
      explorerlink:
        "https://polygonscan.com/address/0x7E5E5957De93D00c352dF75159FbC37d5935f8bF",
    },
    {
      name: "Custom exchange",
    },
  ],
  56: [
    {
      name: "Pancake Swap (BSC)",
      Routeraddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      Dexlink: "https://pancakeswap.finance/",
      explorerlink:
        "https://bscscan.com/address/0x10ED43C718714eb63d5aA57B78B54704E256024E",
    },
    {
      name: "SushiSwap V2 (BSC)",
      Routeraddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      Dexlink: "https://www.sushi.com/swap",
      explorerlink:
        "https://bscscan.com/address/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    },
    {
      name: "ApeSwap (BSC)",
      Routeraddress: "0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
      Dexlink: "https://apeswap.finance/",
      explorerlink:
        "https://bscscan.com/address/0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7",
    },
    {
      name: "Custom exchange",
    },
  ],
  43114: [
    {
      name: "TraderJoe V1 (Avalanche)",
      Routeraddress: "0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
      Dexlink: "https://traderjoexyz.com/avalanche",
      explorerlink:
        "https://snowscan.xyz/address/0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
    },
    {
      name: "Pangolin (Standard pool)",
      Routeraddress: "0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106",
      Dexlink: "Pangolin (Standard pool)",
      explorerlink:
        "https://snowscan.xyz/address/0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106",
    },
    {
      name: "SushiSwap V2 (Avalanche)",
      Routeraddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      Dexlink: "https://www.sushi.com/swap",
      explorerlink:
        "https://snowscan.xyz/address/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    },
    {
      name: "Custom exchange",
    },
  ],
  42161: [
    {
      name: "TraderJoe V1 (Arbitrum One)",
      Routeraddress: "0xbeE5c10Cf6E4F68f831E11C1D9E59B43560B3642",
      Dexlink: "https://traderjoexyz.com/avalanche",
      explorerlink:
        "https://arbiscan.io/address/0xbeE5c10Cf6E4F68f831E11C1D9E59B43560B3642",
    },
    {
      name: "Camelot V2 (LP only version)",
      Routeraddress: "0xc873fEcbd354f5A56E00E710B90EF4201db2448d",
      Dexlink: "https://app.camelot.exchange/",
      explorerlink:
        "https://arbiscan.io/address/0xc873fEcbd354f5A56E00E710B90EF4201db2448d",
    },
    {
      name: "Uniswap V2 (Arbitrum One)",
      Routeraddress: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
      Dexlink: "https://app.uniswap.org/swap",
      explorerlink:
        "https://arbiscan.io/address/0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
    },
    {
      name: "SushiSwap V2 (Arbitrum One)",
      Routeraddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      Dexlink: "https://www.sushi.com/swap",
      explorerlink:
        "https://arbiscan.io/address/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    },
    {
      name: "Custom exchange",
    },
  ],
  10: [
    {
      name: "Uniswap V2 (Optimism)",
      Routeraddress: "0x4A7b5Da61326A6379179b40d00F57E5bbDC962c2",
      Dexlink: "https://app.uniswap.org/swap",
      explorerlink:
        "https://optimistic.etherscan.io/address/0x4A7b5Da61326A6379179b40d00F57E5bbDC962c2",
    },
    {
      name: "Elk Finance (Optimism)",
      Routeraddress: "0xeadE97aFC8f79A8E5Ba85d57C4a4E629b1160C6A",
      Dexlink: "https://app.elk.finance/swap/10/ETH/ELK",
      explorerlink:
        "https://optimistic.etherscan.io/address/0xeadE97aFC8f79A8E5Ba85d57C4a4E629b1160C6A",
    },
    {
      name: "Custom exchange",
    },
  ],
  42220: [
    {
      name: "Uniswap V2",
      Routeraddress: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
      Dexlink: "https://app.uniswap.org/swap",
      explorerlink:
        "https://basescan.org/address/0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
    },
    {
      name: "Elk Finance",
      Routeraddress: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
      Dexlink: "https://app.uniswap.org/swap",
      explorerlink:
        "https://basescan.org/address/0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
    },
    {
      name: "Custom exchange",
    },
  ],
  8453: [
    {
      name: "Uniswap V2 (Base)",
      Routeraddress: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
      Dexlink: "https://app.uniswap.org/swap",
      explorerlink:
        "https://basescan.org/address/0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
    },
    {
      name: "BaseSwap",
      Routeraddress: "0x327Df1E6de05895d2ab08513aaDD9313Fe505d86",
      Dexlink: "https://baseswap.fi/",
      explorerlink:
        "https://basescan.org/address/0x327Df1E6de05895d2ab08513aaDD9313Fe505d86",
    },
    {
      name: "SwapBased",
      Routeraddress: "0xaaa3b1F1bd7BCc97fD1917c18ADE665C5D31F066",
      Dexlink: "https://swapbased.finance/#/",
      explorerlink:
        "https://basescan.org/address/0xaaa3b1F1bd7BCc97fD1917c18ADE665C5D31F066",
    },
    {
      name: "SushiSwap V2 (Base)",
      Routeraddress: "0x6BDED42c6DA8FBf0d2bA55B2fa120C5e0c8D7891",
      Dexlink: "https://www.sushi.com/swap",
      explorerlink:
        "https://basescan.org/address/0x6BDED42c6DA8FBf0d2bA55B2fa120C5e0c8D7891",
    },
    {
      name: "Custom exchange",
    },
  ],
  97: [
    {
      name: "PancakeSwap V2 (BSC Testnet)",
      Routeraddress: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
      Dexlink: "https://pancakeswap.finance/",
      explorerlink:
        "https://testnet.bscscan.com/address/0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    },
    {
      name: "DonaSwap V2 (BSC Testnet)",
      Routeraddress: "0x6E682B51F8bb67294B522b75a1E79dDd4502cc94",
      Dexlink: "https://donaswap.io/",
      explorerlink:
        "https://testnet.bscscan.com/address/0x6E682B51F8bb67294B522b75a1E79dDd4502cc94",
    },
    {
      name: "Custom exchange",
    },
  ],
  250: [
    {
      name: "SpookySwap V2",
      Routeraddress: "0xF491e7B69E4244ad4002BC14e878a34207E38c29",
      Dexlink: "https://v2.spooky.fi/#/",
      explorerlink:
        "https://ftmscan.com/address/0xF491e7B69E4244ad4002BC14e878a34207E38c29",
    },
    {
      name: "WigoSwap",
      Routeraddress: "0x5023882f4D1EC10544FCB2066abE9C1645E95AA0",
      Dexlink: "https://wigoswap.io/",
      explorerlink:
        "https://ftmscan.com/address/0x5023882f4D1EC10544FCB2066abE9C1645E95AA0",
    },
    {
      name: "TombSwap",
      Routeraddress: "0x6D0176C5ea1e44b08D3dd001b0784cE42F47a3A7",
      Dexlink: "https://swap.tomb.com/#/swap",
      explorerlink:
        "https://ftmscan.com/address/0x6D0176C5ea1e44b08D3dd001b0784cE42F47a3A7",
    },
    {
      name: "SushiSwap V2 (Fantom)",
      Routeraddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      Dexlink: "https://www.sushi.com/swap",
      explorerlink:
        "https://ftmscan.com/address/0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    },
    {
      name: "Custom exchange",
    },
  ],
  43113: [
    {
      name: "Pangolin (Standard pool) (Avalanche Fuji)",
      Routeraddress: "0x688d21b0B8Dc35971AF58cFF1F7Bf65639937860",
      Dexlink: "https://app.pangolin.exchange/",
      explorerlink:
        "https://testnet.snowscan.xyz/address/0x688d21b0B8Dc35971AF58cFF1F7Bf65639937860",
    },
    {
      name: "DonaSwap V2 (Avalanche Fuji)",
      Routeraddress: "0x6E682B51F8bb67294B522b75a1E79dDd4502cc94",
      Dexlink: "https://donaswap.io/",
      explorerlink:
        "https://testnet.snowscan.xyz/address/0x6E682B51F8bb67294B522b75a1E79dDd4502cc94",
    },
    {
      name: "Elk Finance (Avalanche Fuji)",
      Routeraddress: "0xdb0D31c562cb6c54c45b27309aAD52b744eFe9fF",
      Dexlink: "https://app.elk.finance/",
      explorerlink:
        "https://testnet.snowscan.xyz/address/0xdb0D31c562cb6c54c45b27309aAD52b744eFe9fF",
    },
    {
      name: "Custom exchange",
    },
  ],
  84532: [
    {
      name: "Uniswap V2",
      Routeraddress: "0x6682375ebC1dF04676c0c5050934272368e6e883",
      Dexlink: "https://app.uniswap.org/swap",
      explorerlink:
        "https://sepolia.basescan.org/address/0x6682375ebc1df04676c0c5050934272368e6e883",
    },
    // {
    //   name: "BaseSwap",
    //   Routeraddress: "0x327Df1E6de05895d2ab08513aaDD9313Fe505d86",
    //   Dexlink: "https://baseswap.fi/",
    //   explorerlink:
    //     "https://basescan.org/address/0x327Df1E6de05895d2ab08513aaDD9313Fe505d86",
    // },
    // {
    //   name: "SwapBased",
    //   Routeraddress: "0xaaa3b1F1bd7BCc97fD1917c18ADE665C5D31F066",
    //   Dexlink: "https://swapbased.finance/#/",
    //   explorerlink:
    //     "https://basescan.org/address/0xaaa3b1F1bd7BCc97fD1917c18ADE665C5D31F066",
    // },
    // {
    //   name: "SushiSwap V2 (Base)",
    //   Routeraddress: "0x6BDED42c6DA8FBf0d2bA55B2fa120C5e0c8D7891",
    //   Dexlink: "https://www.sushi.com/swap",
    //   explorerlink:
    //     "https://basescan.org/address/0x6BDED42c6DA8FBf0d2bA55B2fa120C5e0c8D7891",
    // },
    {
      name: "Custom exchange",
    },
  ],
};
