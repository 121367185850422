import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepButton from "@mui/joy/StepButton";
import StepIndicator from "@mui/joy/StepIndicator";
import General from "./General";
import DefaultExchange from "./DefaultExchange";
import Taxes from "./Taxes";
import Summary from "./Summary";
import { useMediaQuery } from "@mui/material";

const steps = ["General", "Optional", "Taxes", "Summary"];

function SmartContractStepper() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [activStep, setActivStep] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  // console.log("activStep", activStep);

  const handleTabChange = (tab, step) => {
    setActiveTab(tab);
    setActivStep(step);
  };

  return (
    <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile">
      <Box sx={{ width: "90%" }} className="mx-auto">
        {/* <Stepper
          orientation={isSmallScreen ? "vertical" : "horizontal"}
          sx={{ width: "100%", fontFamily: "'DM Sans', sans-serif" }}
        >
          {steps.map((step, index) => (
            <Step
              key={step}
              sx={{
                "&::after": {
                  content: '""',
                  width: "100%",
                  height: "2px",
                  display: "block",
                  bgcolor: activStep > index ? "rgb(6 141 114)" : "#8A8A8A",
                  transition: "bgcolor 0.3s ease-in-out",
                },
              }}
            >
              <StepIndicator
                variant="soft"
                sx={{
                  bgcolor: activStep >= index ? "rgb(6 141 114)" : "#242525",
                  color: "white",
                  padding: "10px",
                  width: "40px",
                  height: "40px",
                }}
              >
                {index + 1}
              </StepIndicator>
              <StepButton
                sx={{ color: "white" }}
                onClick={() => handleTabChange(`tab${index + 1}`, index)}
              >
                {step}
              </StepButton>
            </Step>
          ))}
        </Stepper> */}
        <Stepper orientation={isSmallScreen ? "vertical" : "horizontal"}>
          {steps.map((step, index) => (
            <Step
              sx={{
                "&::after": {
                  content: '""',
                  width: "100%",
                  height: "2px",
                  display: "block",
                  bgcolor: activStep > index ? "#45D09A" : "#8A8A8A",
                  transition: "bgcolor 0.3s ease-in-out",
                  marginRight: "1.5rem",
                },
              }}
              key={index}
              indicator={
                <StepIndicator
                  sx={{
                    bgcolor: activStep >= index ? "#45D09A" : "#242525",
                    color: "white",
                    padding: "15px",
                    width: "40px",
                    height: "40px",
                    // cursor: "pointer",
                    borderRadius: "100%",
                    marginRight: "0.5rem",
                  }}
                  // onClick={() => handleTabChange(`tab${index + 1}`, index)}
                >
                  {index + 1}
                </StepIndicator>
              }
            >
              <p
                style={{
                  // cursor: "pointer",
                  color: activStep >= index ? "#45D09A" : "#F1F1F1",
                  fontWeight: "500",
                }}
                // onClick={() => handleTabChange(`tab${index + 1}`, index)}
              >
                {step}
              </p>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div className="mt-4">
        <div
          className="tab-content shadow-none p-0 "
          id="myTabContent"
          // style={{ backgroundColor: "#17171B" }}
        >
          <div
            className={`tab-pane tab-pane-parent fade px-0 ${
              activeTab === "tab1" ? "show active" : ""
            }`}
            id="tab1"
            role="tabpanel"
            aria-labelledby="tab1-tab"
          >
            <General setTab={setActiveTab} setStepper={setActivStep} />
          </div>
          <div
            className={`tab-pane tab-pane-parent fade px-0 ${
              activeTab === "tab2" ? "show active" : ""
            }`}
            id="tab2"
            role="tabpanel"
            aria-labelledby="tab2-tab"
          >
            <DefaultExchange setTab={setActiveTab} setStepper={setActivStep} />
          </div>
          <div
            className={`tab-pane tab-pane-parent fade px-0 ${
              activeTab === "tab3" ? "show active" : ""
            }`}
            id="tab3"
            role="tabpanel"
            aria-labelledby="tab3-tab"
          >
            <Taxes setTab={setActiveTab} setStepper={setActivStep} />
          </div>
          <div
            className={`tab-pane tab-pane-parent fade px-0 ${
              activeTab === "tab4" ? "show active" : ""
            }`}
            id="tab4"
            role="tabpanel"
            aria-labelledby="tab4-tab"
          >
            <Summary setTab={setActiveTab} setStepper={setActivStep} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmartContractStepper;
