import Navigation from "../Components/Home/Navigation";
import "../Components/Home/Home.css";
import Hero from "../Components/Home/Hero";
import PoweredBy from "../Components/Home/PoweredBy";
import Features from "../Components/Home/Features";
import Tutorial from "../Components/Home/Tutorial";
import Statistics from "../Components/Home/Statistics";
import Pricing from "../Components/Home/Pricing";
import Footer from "../Components/Home/Footer";
import Products from "../Components/Home/Products";

const Home = () => {
  return (
    <>
      <Navigation />
      <Hero />
      <PoweredBy />
      <Features />
      <Tutorial />
      <Statistics />
      <Products />
      <Pricing />
      <Footer />
    </>
  );
};

export default Home;
