import React from "react";
import InputHeader from "./InputHeader";
import OwnerOnlyWrapper from "./OwnerOnlyWrapper/OwnerOnlyWrapper";
import InputGroup from "./InputGroup";
import CopyText from "./CopyText/CopyText";

const MaxAmountPerWallet = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Max amount per wallet</p>
      <InputHeader
        label="Max wallet amount"
        subHeading="maxWalletAmount( ) - Max amount of AT each user can hold"
      />
      <p style={{ color: "#0AE7FE" }}>1 111AT</p>
      {/* Update max wallet amount */}
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Update max wallet amount"
          subHeading={
            "updateMaxWalletAmount( amount ) - Change max amount of AT per wallet"
          }
          InputLabel1="Amount"
          InputPlaceHolder1="123,456"
          ButtonLabel="Update max wallet amount"
          margin="my-0"
        />
      </OwnerOnlyWrapper>
      {/* Is excluded from limits? */}
      <InputGroup
        Label="Is excluded from limits?"
        subHeading={
          "isExcludedFromLimits( address ) - Check if address is excluded from limits. This exclusion affects all similar features in your token (Max amount per wallet, Max transaction limits & Anti-bot cooldown) depending whether you have them."
        }
        InputLabel1="Address"
        InputPlaceHolder1="0x......."
        ButtonLabel="Is excluded from limits?"
      />
      {/* Exclude from limits  */}
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Exclude from limits"
          subHeading="excludeFromLimits( address, isExcluded ) - Excludes or includes address in limit features."
          InputLabel1="Address"
          InputPlaceHolder1="0x"
          OptionLabel="Excluded status"
          Options={["True (Included)", "False (Excluded)"]}
          ButtonLabel="Excluded from limits"
          margin="my-0"
        />
      </OwnerOnlyWrapper>
      <InputHeader
        label="Excluded from LIMITS by default"
        subHeading="Below addresses were automatically excluded to ensure proper token functionality. Including them might result in huge stability problems and errors. Don't include them unless you know what you are doing!"
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"Supply recipient"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793"}
        label={"Token smart contract"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"0x0 (burn) address"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"#wallet tax recipient"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"DEX Router smart contract"}
      />
      <CopyText
        contactaddress={"0x35c68bFd0b55793ba"}
        label={"DEX Pair smart contract"}
      />
    </div>
  );
};

export default MaxAmountPerWallet;
