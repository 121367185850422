import { createSlice } from "@reduxjs/toolkit";

const ResetDownloadFileSlice = createSlice({
  name: "resetDownload",
  initialState: {
    resetDownload: false,
  },
  reducers: {
    setResetDownload: (state, action) => {
      state.resetDownload = action.payload;
    },
  },
});

export const { setResetDownload } = ResetDownloadFileSlice.actions;
export default ResetDownloadFileSlice.reducer;
