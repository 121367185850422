import { Step, StepIndicator, Stepper } from "@mui/joy";
import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import TonGeneral from "./Ton General/TonGeneral";
import TonSummary from "./Ton Summary/TonSummary";

const steps = ["General", "Summary"];

const TonTokenStepper = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [activStep, setActivStep] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleTabChange = (tab, step) => {
    setActiveTab(tab);
    setActivStep(step);
  };
  return (
    <div className="px-3 px-lg-6 px-xxl-13 py-5 py-lg-10 my-profile">
      <Box sx={{ width: "90%" }} className="mx-auto">
        <Stepper orientation={isSmallScreen ? "vertical" : "horizontal"}>
          {steps.map((step, index) => (
            <Step
              sx={{
                "&::after": {
                  content: '""',
                  width: "100%",
                  height: "2px",
                  display: "block",
                  bgcolor: activStep > index ? "rgb(6 141 114)" : "#8A8A8A",
                  transition: "bgcolor 0.3s ease-in-out",
                  marginRight: "1.5rem",
                },
              }}
              key={index}
              indicator={
                <StepIndicator
                  sx={{
                    bgcolor: activStep >= index ? "rgb(6 141 114)" : "#242525",
                    color: "white",
                    padding: "15px",
                    width: "40px",
                    height: "40px",
                    // cursor: "pointer",
                    borderRadius: "100%",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => handleTabChange(`tab${index + 1}`, index)}
                >
                  {index + 1}
                </StepIndicator>
              }
            >
              <p
                style={{
                  // cursor: "pointer",
                  color: "#F1F1F1",
                  fontWeight: "500",
                }}
                onClick={() => handleTabChange(`tab${index + 1}`, index)}
              >
                {step}
              </p>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div className="mt-4">
        <div
          className="tab-content shadow-none p-0 "
          id="myTabContent"
          // style={{ backgroundColor: "#17171B" }}
        >
          <div
            className={`tab-pane tab-pane-parent fade px-0 ${
              activeTab === "tab1" ? "show active" : ""
            }`}
            id="tab1"
            role="tabpanel"
            aria-labelledby="tab1-tab"
          >
            <TonGeneral setTab={setActiveTab} setStepper={setActivStep} />
          </div>
          <div
            className={`tab-pane tab-pane-parent fade px-0 ${
              activeTab === "tab2" ? "show active" : ""
            }`}
            id="tab2"
            role="tabpanel"
            aria-labelledby="tab2-tab"
          >
            <TonSummary setTab={setActiveTab} setStepper={setActivStep} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TonTokenStepper;
