import { useState } from "react";

const useTonGeneralFormHandler = (initialState, validate) => {
  const formatWithSpaces = (value) => {
    // Remove non-digit characters and format with spaces after every 3 digits
    return value?.replace(/\D/g, "")?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const getInitialData = () => {
    const savedData = localStorage.getItem("tonGeneral");
    if (savedData) {
      const parsedData = JSON.parse(savedData);

      if (parsedData?.mintToken) {
        parsedData.mintToken = formatWithSpaces(parsedData.mintToken);
      }
      return parsedData;
    }
    return initialState;
  };

  const [formData, setFormData] = useState(getInitialData);
  const [errors, setErrors] = useState({});

  const saveToLocalStorage = (data) => {
    const formattedData = {
      ...data,
      mintToken: data.mintToken.replace(/\s/g, ""),
    };
    localStorage.setItem("tonGeneral", JSON.stringify(formattedData));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Apply formatting only for initialSupply
    let formattedValue = value;
    if (name === "mintToken") {
      formattedValue = formatWithSpaces(value);
    }

    // Remove spaces to keep only the numeric value in formData for initialSupply
    const numericValue =
      name === "mintToken" ? formattedValue?.replace(/\s/g, "") : value;

    setFormData((prevData) => {
      const newFormData = { ...prevData, [name]: numericValue };
      saveToLocalStorage(newFormData);
      return newFormData;
    });

    if (validate) {
      const error = validate(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }
  };

  const handleIncrement = (fieldName, maxLimit) => {
    setFormData((prevData) => {
      let newValue = prevData[fieldName];
      if (newValue < maxLimit) {
        newValue++;
      }
      const newFormData = { ...prevData, [fieldName]: newValue };
      saveToLocalStorage(newFormData);
      return newFormData;
    });
  };

  // Handle decrement logic
  const handleDecrement = (fieldName, minLimit) => {
    setFormData((prevData) => {
      let newValue = prevData[fieldName];
      if (newValue > minLimit) {
        newValue--;
      }
      const newFormData = { ...prevData, [fieldName]: newValue };
      saveToLocalStorage(newFormData);
      return newFormData;
    });
  };

  const handleSubmit = (onSubmit) => {
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0 && onSubmit) {
      onSubmit(formData);
    }
  };

  const validateForm = () => {
    const validationErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validate(key, formData[key]);
      if (error) validationErrors[key] = error;
    });
    return validationErrors;
  };

  return {
    formData,
    errors,
    formatWithSpaces,
    handleChange,
    handleIncrement,
    handleDecrement,
    handleSubmit,
  };
};

export default useTonGeneralFormHandler;
