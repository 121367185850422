import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import General from "../../../FormsComponents/Ton Token/General/General";
import useTonGeneralFormHandler from "../../../../Hooks/Ton Token/useTonGeneralFormHandler";
import { validateTonGeneralField } from "../../../../Utils/validation";
import { scrollToTop } from "../../../../Utils/helperFunction";

const TonGeneral = ({ setTab, setStepper }) => {
  const initialState = {
    tokenName: "",
    tokenSymbol: "",
    decimals: 9,
    mintToken: "",
    description: "",
  };
  const {
    formData,
    errors,
    formatWithSpaces,
    handleChange,
    handleIncrement,
    handleDecrement,
    handleSubmit,
  } = useTonGeneralFormHandler(initialState, (name, value) =>
    validateTonGeneralField(name, value, formData)
  );

  const stepUpdate = () => {
    console.log("Ton general Form Data", formData);
    handleSubmit((data) => {
      console.log("General Form Data for Ton TOken", data);
      setTab("tab2");
      setStepper(1);
      scrollToTop();
    });
  };
  return (
    <>
      <div
        className="rounded mx-auto p-4"
        style={{ backgroundColor: "#17171B", width: "95%" }}
      >
        {/* <ResetButton /> */}
        <div style={{ color: "#9B9797" }}>
          <p>General</p>
          <hr />
        </div>
        <Form>
          <General
            formData={formData}
            errors={errors}
            formatWithSpaces={formatWithSpaces}
            onChange={handleChange}
            handleIncrement={(fieldName) => handleIncrement(fieldName, 9)}
            handleDecrement={(fieldName) => handleDecrement(fieldName, 0)}
          />
          <Col md={3} className="text-center mt-5 mx-auto">
            <Button
              onClick={() => stepUpdate()}
              className="w-100 mb-3 bg-btn-color border-0"
              style={{
                borderRadius: "4px",
              }}
            >
              Save & Next
            </Button>
          </Col>
        </Form>
      </div>
    </>
  );
};

export default TonGeneral;
