import React from "react";
import CheckIcon from "../../Images/Home/tick.png";

const FeatureCard = ({ data }) => {
  return (
    <>
      <div className="text-light">
        <h2>{data.title}</h2>
        <div>
          {data.feat.map((item) => (
            <div className="d-flex align-items-center gap-2 mb-3">
              <div>
                <img src={CheckIcon} alt="" />
              </div>
              <p>{item}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const Features = () => {
  const featuresData = [
    {
      id: 1,
      title: "Taxes",
      feat: [
        "Liquidity tax",
        "Dividend tax",
        "Auto-burn tax",
        "Wallet taxes(up to 5)",
      ],
    },
    {
      id: 2,
      title: "Limits",
      feat: [
        "Anti-bot cooldown",
        "Max amount per wallet",
        "Max transaction limit",
      ],
    },
    {
      id: 3,
      title: "Utilities",
      feat: ["Default exchange", "Foreign token recovery", "Permit"],
    },
    {
      id: 4,
      title: "Other",
      feat: ["Mintable", "Enable Trading function", "Pausable", "Blacklist"],
    },
  ];
  return (
    <section className="home-wrapper" id="feature">
      {/* Main Container */}
      <div className="feature-container">
        <div className="text-center my-5">
          <p style={{ color: "var(--home-text-color)" }}>Features</p>
          <h2 className="feature-heading">14 Customizable features</h2>
        </div>
        <div className="d-flex align-items-start gap-5 justify-content-center flex-wrap">
          {featuresData.map((item) => (
            <FeatureCard data={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
