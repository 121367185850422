import React from "react";
import SingleSummaryRow from "../../../FormsComponents/SingleSummaryRow";
import SummaryFooter from "./SummaryFooter";
import { scrollToTop } from "../../../../Utils/helperFunction";
import useLocalStorage from "../../../../Hooks/Spl Token/useLocalStorage";
import {
  formatWithSpaces,
  truncateString,
} from "../../../../Utils/SplHelperFunction";

const TonSummary = ({ setTab, setStepper }) => {
  const tonGeneralData = useLocalStorage("tonGeneral");
  console.log("Ton General Data", tonGeneralData);

  const handlePrevious = () => {
    setTab("tab1");
    setStepper(0);
    scrollToTop();
  };
  return (
    <div>
      <div className="container py-4" style={{ backgroundColor: "#17171B" }}>
        <div className="my-4 summary-table-heading-color">
          <SingleSummaryRow
            Label={`${tonGeneralData?.tokenName} ${tonGeneralData?.tokenSymbol}`}
            firstHeader="true"
            // Value={
            //   wallet?.connected
            //     ? truncateText(wallet?.publicKey?.toBase58())
            //     : "--"
            // }
          />
        </div>
        <SingleSummaryRow
          Label={"Token Name"}
          Value={`${tonGeneralData?.tokenName}`}
        />
        <SingleSummaryRow
          Label={"Token Symbol"}
          Value={`${tonGeneralData?.tokenSymbol}`}
        />
        <SingleSummaryRow
          Label={"Decimals"}
          Value={`${tonGeneralData?.decimals}`}
        />
        <SingleSummaryRow
          Label={"Tokens to Mint"}
          Value={`${formatWithSpaces(tonGeneralData?.mintToken)}`}
        />
        <SingleSummaryRow
          Label={"Description"}
          Value={`${truncateString(tonGeneralData?.description, 30)}`}
        />
        <SummaryFooter handlePrevious={handlePrevious} />
      </div>
    </div>
  );
};

export default TonSummary;
