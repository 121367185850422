import { configureStore } from "@reduxjs/toolkit";
import GeneralSlice from "./Slices/GeneralSlice";
import AppSlice from "./Slices/AppSlice";
import DefaultExchangeSlice from "./Slices/DefaultExchangeSlice";
import ResetPaymentSlice from "./Slices/ResetPaymentSlice";
import ResetDownloadFileSlice from "./Slices/ResetDownloadFileSlice";

const store = configureStore({
  reducer: {
    general: GeneralSlice,
    app: AppSlice,
    defaultExchange: DefaultExchangeSlice,
    resetPayment: ResetPaymentSlice,
    resetDownload: ResetDownloadFileSlice,
  },
});

export default store;
