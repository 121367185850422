import React from "react";

const divider = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "2px",
        background: "#3F3F3F",
        margin: "0.5rem 0",
      }}
    ></div>
  );
};

export default divider;
