function SingleSummaryRow({
  Label,
  Value,
  firstHeader,
  heading,
  subHeading,
  nested,
}) {
  return (
    <tr
      className={`d-flex justify-content-between  ${
        heading ? "py-3" : "py-2"
      } px-2 `}
      style={{
        border: "1px solid #2D2D2D",
      }}
    >
      <td
        style={{
          color: `${firstHeader || heading ? "#45d09a" : "#9B9797"}`,
          fontWeight: `${subHeading ? "bold" : ""}`,
          paddingLeft: `${nested ? "10px" : ""}`,
        }}
        // className={`${heading ? "text-white" : ""} `}
      >
        <div
          className="d-flex align-items-center gap-2"
          style={{ color: "#45d09a" }}
        >
          {nested && (
            <div
              style={{
                width: "5px",
                height: "5px",
                backgroundColor: "#9B9797",
                borderRadius: "50%",
              }}
            ></div>
          )}
          {Label}
        </div>
      </td>
      {!heading && (
        <td
          className="table-text-color"
          style={{ color: `${firstHeader ? "#45d09a" : ""}` }}
        >
          {Value}
        </td>
      )}
    </tr>
  );
}

export default SingleSummaryRow;
