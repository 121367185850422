import React from "react";
import OwnerOnlyWrapper from "./OwnerOnlyWrapper/OwnerOnlyWrapper";
import InputGroup from "./InputGroup";

const ForeignTokenRecovery = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Foreign token recovery</p>
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Recover ERC-20 tokens"
          subHeading="recoverERC20( tokenAddress, amount) - Recover foreign ERC-20 tokens stuck on AT smart contract"
          InputLabel1="ERC-20 token address"
          InputPlaceHolder1="0x"
          InputLabel2="Amount"
          InputPlaceHolder2="Ex:123456"
          ButtonLabel="Recover ERC-20"
          margin="my-0"
        />
      </OwnerOnlyWrapper>
    </div>
  );
};

export default ForeignTokenRecovery;
