import TonTokenStepper from "../Components/Forms/Ton Token/TonTokenStepper";

const TonToken = () => {
  return (
    <div>
      <TonTokenStepper />
    </div>
  );
};

export default TonToken;
