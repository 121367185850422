import React from "react";
import InputGroup from "./InputGroup";
import OwnerOnlyWrapper from "./OwnerOnlyWrapper/OwnerOnlyWrapper";

const Blacklist = () => {
  return (
    <div className="px-4 py-3 rounded-2" style={{ background: "#17171B" }}>
      <p className="text-white">Blacklist</p>
      <InputGroup
        Label="Is blacklisted?"
        subHeading={
          "blacklist( address ) -  Check is the address is blacklist and cannot make any transactions."
        }
        InputLabel1="Address"
        InputPlaceHolder1="0x......."
        ButtonLabel="Check blacklist"
      />
      <OwnerOnlyWrapper>
        <InputGroup
          Label="Blacklist"
          subHeading="Blacklist( address, isBlacklisted ) - Adds or removes the address from the blacklist"
          InputLabel1="Address"
          InputPlaceHolder1="0x"
          OptionLabel="Blacklist status"
          Options={["True (Add)", "False (Remove)"]}
          ButtonLabel="Change blacklist status"
          margin="my-0"
        />
      </OwnerOnlyWrapper>
    </div>
  );
};

export default Blacklist;
