import { useEffect, useState } from "react";

const useLocalStorage = (key) => {
  const [value, setValue] = useState(() => {
    // Get the initial value from localStorage
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
      console.error("Error parsing localStorage value:", error);
      return null;
    }
  });

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const updatedValue = localStorage.getItem(key);
        setValue(updatedValue ? JSON.parse(updatedValue) : null);
      } catch (error) {
        console.error("Error parsing localStorage value:", error);
      }
    };

    // Override localStorage.setItem to trigger updates in the same tab
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (...args) {
      originalSetItem.apply(this, args);
      handleStorageChange();
    };

    // Listen for storage events from other tabs
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      // Restore the original setItem method
      localStorage.setItem = originalSetItem;
    };
  }, [key]);

  return value;
};

export default useLocalStorage;
