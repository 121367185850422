import { Outlet, useLocation } from "react-router";
import NavigationBar from "./Components/NavigationBar";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SplTokenNavbar from "./Components/SplTokenNavbar";
import TonTokenNavbar from "./Components/TonTokenNavbar";

const MainApp = () => {
  const location = useLocation();
  const pathName = location.pathname.split("/").pop();

  return (
    <div>
      {pathName == "spltoken" ? (
        <SplTokenNavbar />
      ) : pathName == "tontoken" ? (
        <TonTokenNavbar />
      ) : (
        <NavigationBar />
      )}
      <Outlet />
    </div>
  );
};

export default MainApp;
