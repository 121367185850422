import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const CooldownToggle = ({
  Description,
  InputDescription,
  Label,
  Error,
  Placeholder1,
  Placeholder2,
  Options = [],
  toggleName,
  checked,
  toggleOnChange,
  inputName,
  inputValue,
  inputOnChange,
  antibotCoolDownTimeName,
  antibotCoolDownTimeValue,
  antibotCoolDownTimeOnChange,
}) => {
  return (
    <Col md={4}>
      {/* <Form.Check
        className="mt-3"
        style={{ color: "#F1F1F1" }}
        type="switch"
        id={Label}
        name={toggleName}
        checked={checked}
        onChange={toggleOnChange}
        label={Label}
      /> */}
      <div className="toggle-switch-container">
        <label className="switch">
          <input
            type="checkbox"
            name={toggleName}
            id={Label}
            checked={checked}
            onChange={toggleOnChange}
          />
          <span className="slider" />
        </label>
        <label htmlFor={Label} style={{ color: "#45d09a" }}>
          {Label}
        </label>
      </div>
      <div style={{ color: "#45d09a" }} className="mt-3">
        {Description}
      </div>

      {checked && (
        <div>
          <Col md={12} className="d-flex align-items-center gap-3 mt-2">
            <div>
              <Form.Control
                className="placeholderColor"
                style={{
                  color: "#FFF",
                  borderColor: "#45D09A",
                  backgroundColor: "#45D09A33",
                  boxShadow: "none",
                }}
                placeholder={Placeholder1}
                id="InputText"
                name={inputName}
                onChange={inputOnChange}
                value={inputValue}
              />
            </div>
            <div>
              <Form.Control
                style={{
                  color: "#FFF",
                  borderColor: "#45D09A",
                  backgroundColor: "#45D09A33",
                  boxShadow: "none",
                }}
                className="bg-transparent placeholderColor"
                as="select"
                placeholder={Placeholder2}
                id="InputText"
                name={antibotCoolDownTimeName}
                onChange={antibotCoolDownTimeOnChange}
                value={antibotCoolDownTimeValue}
              >
                {Options.map((option, index) => (
                  <option key={index} value={option} className="bg-dark">
                    {option}
                  </option>
                ))}
              </Form.Control>
            </div>
          </Col>
          <div style={{ color: Error ? "red" : "#45d09a" }} className="mt-3">
            {InputDescription}
          </div>
        </div>
      )}
    </Col>
  );
};

export default CooldownToggle;
