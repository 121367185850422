import { Buffer } from "buffer";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import useLocalStorage from "../../../Hooks/Spl Token/useLocalStorage";
import { scrollToTop, truncateText } from "../../../Utils/helperFunction";
import SingleSummaryRow from "../../FormsComponents/SingleSummaryRow";
import GeneralSummaryInfo from "../../FormsComponents/Spl Token/Summary/GeneralSummaryInfo";
import MetaDataSummaryInfo from "../../FormsComponents/Spl Token/Summary/MetaDataSummaryInfo";
import OptionalSummaryInfo from "../../FormsComponents/Spl Token/Summary/OptionalSummaryInfo";
import SummaryFooter from "../../FormsComponents/Spl Token/Summary/SummaryFooter";
import TransferTaxSummaryInfo from "../../FormsComponents/Spl Token/Summary/TransferTaxSummaryInfo";
import { useCallback, useEffect, useState } from "react";
import SplModal from "../../FormsComponents/Spl Token/Modal/SplModal";
import PaymentInfo from "../../FormsComponents/Spl Token/Modal/PaymentInfo";
import "../../FormsComponents/Spl Token/Modal/SplModal.css";
import useAccountBalance from "../../../Hooks/Spl Token/useAccountBalance";
import { useMetaData } from "../../../Utils/MetaDataContext";
import {
  Keypair,
  SystemProgram,
  Transaction,
  PublicKey,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
  AuthorityType,
  createAssociatedTokenAccountInstruction,
  createInitializeInstruction,
  createInitializeMetadataPointerInstruction,
  createInitializeMintInstruction,
  createInitializeTransferFeeConfigInstruction,
  createMintToInstruction,
  createSetAuthorityInstruction,
  ExtensionType,
  getAssociatedTokenAddressSync,
  getMintLen,
  LENGTH_SIZE,
  TOKEN_2022_PROGRAM_ID,
  TYPE_SIZE,
} from "@solana/spl-token";
import {
  createUpdateAuthorityInstruction,
  createUpdateFieldInstruction,
  pack,
} from "@solana/spl-token-metadata";
import { sendImageToIPFS } from "../../../ContractAction/pinata/uploadLogo";
import { sendMetadataToIPFS } from "../../../ContractAction/pinata/uploadJson";
import JSBI from "jsbi";

const PLATFORM_FEE = 0.1; // 0.1 SOL
const PLATFORM_ADDRESS = new PublicKey(
  "C2SAnmUuwKs2t788FJh2H2cuqbcV6qD89eyDim2bt9Lu"
);

const SplSummary = ({ setTab, setStepper }) => {
  const splGeneralData = useLocalStorage("splGeneral");
  const splMetaData = useLocalStorage("splMetaData");
  const splOptionalData = useLocalStorage("splOptional");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mint, setMint] = useState("");
  const wallet = useWallet();
  const { connection } = useConnection();

  if (typeof global !== "undefined") {
    global.Buffer = Buffer;
  } else if (typeof window !== "undefined") {
    window.Buffer = Buffer;
  }

  // const { balance, loading, error } = useAccountBalance(wallet.publicKey);

  const { tokenLogoImgFile } = useMetaData();

  // console.log("Image File form MetaData", tokenLogoImgFile);
  console.log("wallet Solana address", wallet.publicKey);
  // console.log("Wallet Balance", balance);
  console.log("splOptionalData", splOptionalData);

  useEffect(() => {
    if (wallet.connected && wallet.publicKey) {
      console.log("Connected wallet address:", wallet?.publicKey?.toBase58());
    } else {
      console.log("No wallet connected or public key is null.");
    }
  }, [wallet.connected, wallet.publicKey]);

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const checkboxProps = {
    isCheckboxChecked,
    handleCheckboxChange,
  };

  // Create Token Handler
  const createTokenHandler = useCallback(async () => {
    try {
      // Image URL
      // const imageURI = await sendImageToIPFS(tokenLogoImgFile);
      // if (!imageURI) throw new Error("Error uploading logo to IPFS");

      // Metadata URI
      const metadataIpfs = JSON.stringify({
        name: splGeneralData?.tokenName,
        symbol: splGeneralData?.tokenSymbol,
        decimals: Number(splGeneralData?.decimals),
        description: splMetaData?.tokenDescriptionInput,
        // image: imageURI,
      });
      // const metadataURI = await sendMetadataToIPFS(metadataIpfs);
      // if (!metadataURI) throw new Error("Error uploading metadata to IPFS");

      const mintKeypair = Keypair.generate();
      setMint(mintKeypair.publicKey?.toBase58());

      const freezeAuthority = splOptionalData?.freezable
        ? null
        : wallet.publicKey;

      // Authority that can modify transfer fees
      const transferFeeConfigAuthority = wallet.publicKey;

      // Authority that can move tokens withheld on mint or token accounts
      const withdrawWithheldAuthority = wallet.publicKey;

      console.log("Transfer Config Authority and WithDraw", {
        transferFeeConfigAuthority,
        withdrawWithheldAuthority,
      });

      // MetaData
      const metadata = {
        mint: mintKeypair.publicKey,
        name: splGeneralData?.tokenName,
        symbol: splGeneralData?.tokenSymbol,
        // uri: metadataURI,
        additionalMetadata: [
          ["description", splMetaData?.tokenDescriptionInput],
        ],
      };

      // Extension
      const extensions = [ExtensionType.MetadataPointer];
      const mintLen = getMintLen(extensions);

      // Enable Transfer Fees
      if (splOptionalData?.transferTax) {
        extensions.push(ExtensionType.TransferFeeConfig);
      }

      // const mintLen = getMintLen(extensions);
      // const metadataLen = TYPE_SIZE + LENGTH_SIZE + pack(metadata).length;
      // const lamports = await connection.getMinimumBalanceForRentExemption(
      //   mintLen + metadataLen
      // );

      const lamports = await connection.getMinimumBalanceForRentExemption(
        mintLen
      );

      // Enable Transfer Fees
      // if (splOptionalData?.transferTax) {
      //   extensions.push(ExtensionType.TransferFeeConfig);
      // }

      // const mintLen = getMintLen(extensions);
      // const metadataLen = TYPE_SIZE + LENGTH_SIZE + pack(metadata).length;
      // const lamports = await connection.getMinimumBalanceForRentExemption(
      //   mintLen + metadataLen
      // );

      // Instance of Transcation
      const transaction = new Transaction();

      // Add instruction to send platform fee
      transaction.add(
        SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: PLATFORM_ADDRESS,
          lamports: PLATFORM_FEE * LAMPORTS_PER_SOL,
        })
      );

      // Add instructions for creating and initializing the token
      transaction.add(
        SystemProgram.createAccount({
          fromPubkey: wallet.publicKey,
          newAccountPubkey: mintKeypair.publicKey,
          space: mintLen,
          lamports,
          programId: TOKEN_2022_PROGRAM_ID,
        }),
        createInitializeMetadataPointerInstruction(
          mintKeypair.publicKey,
          wallet.publicKey,
          mintKeypair.publicKey,
          TOKEN_2022_PROGRAM_ID
        )
      );

      if (splOptionalData?.transferTax) {
        transaction.add(
          createInitializeTransferFeeConfigInstruction(
            mintKeypair.publicKey,
            transferFeeConfigAuthority,
            withdrawWithheldAuthority,
            Number(parseFloat(splOptionalData?.taxRate) * 100),
            JSBI.BigInt(splOptionalData?.maxFeePerTxn),
            TOKEN_2022_PROGRAM_ID
          )
        );
      }

      console.log("Decimals after click", splGeneralData?.decimals);
      console.log("Mint pair after click", mintKeypair?.publicKey);
      console.log("Public Key after click", wallet.publicKey);
      console.log("Freeze after click", freezeAuthority);
      transaction.add(
        createInitializeMintInstruction(
          mintKeypair.publicKey,
          Number(splGeneralData?.decimals),
          wallet.publicKey,
          freezeAuthority,
          TOKEN_2022_PROGRAM_ID
        ),
        createInitializeInstruction({
          programId: TOKEN_2022_PROGRAM_ID,
          mint: mintKeypair.publicKey,
          metadata: mintKeypair.publicKey,
          name: metadata.name,
          symbol: metadata.symbol,
          uri: metadata.uri,
          mintAuthority: wallet.publicKey,
          updateAuthority: wallet.publicKey,
        })
        // createUpdateFieldInstruction({
        //   programId: TOKEN_2022_PROGRAM_ID,
        //   metadata: mintKeypair?.publicKey,
        //   updateAuthority: wallet?.publicKey,
        //   field: metadata?.additionalMetadata[0][0],
        //   value: metadata?.additionalMetadata[0][1],
        // })
      );

      // Add instructions for minting tokens and revoking authorities
      const associatedToken = getAssociatedTokenAddressSync(
        mintKeypair.publicKey,
        wallet.publicKey,
        false,
        TOKEN_2022_PROGRAM_ID
      );

      const mintAmount = JSBI.BigInt(
        Number(splGeneralData?.initialSupply) *
          10 ** Number(splGeneralData?.decimals)
      );

      transaction.add(
        createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          associatedToken,
          wallet.publicKey,
          mintKeypair.publicKey,
          TOKEN_2022_PROGRAM_ID
        ),
        createMintToInstruction(
          mintKeypair.publicKey,
          associatedToken,
          wallet.publicKey,
          mintAmount,
          [],
          TOKEN_2022_PROGRAM_ID
        )
      );

      // Revoke Mint
      if (splOptionalData?.mintable) {
        transaction.add(
          createSetAuthorityInstruction(
            mintKeypair.publicKey,
            wallet.publicKey,
            AuthorityType.MintTokens,
            null,
            [],
            TOKEN_2022_PROGRAM_ID
          )
        );
      }

      // if (splMetaData?.immutableMetadata) {
      //   transaction.add(
      //     createUpdateAuthorityInstruction({
      //       programId: TOKEN_2022_PROGRAM_ID,
      //       metadata: mintKeypair?.publicKey,
      //       oldAuthority: wallet?.publicKey,
      //       newAuthority: null,
      //     })
      //   );
      // }

      // Final Transcation Phase
      transaction.feePayer = wallet.publicKey;
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      transaction.partialSign(mintKeypair);

      const txhash = await wallet.sendTransaction(transaction, connection);

      if (txhash) {
        console.log("Transcation Successfully Done!!");
      } else {
        throw new Error("Error Creating the Token!");
      }
    } catch (error) {
      console.log("Create Token Handler Error", error);
    }
  }, []);

  const handlePrevious = () => {
    setTab("tab3");
    setStepper(2);
    scrollToTop();
  };

  return (
    <>
      <div className="container py-4" style={{ backgroundColor: "#17171B" }}>
        <div className="my-4 summary-table-heading-color">
          <SingleSummaryRow
            Label={`${splGeneralData?.tokenName} (${splGeneralData?.tokenSymbol})`}
            firstHeader="true"
            Value={
              wallet?.connected
                ? truncateText(wallet?.publicKey?.toBase58())
                : "--"
            }
          />
        </div>
        <GeneralSummaryInfo />
        <OptionalSummaryInfo />
        <TransferTaxSummaryInfo />
        <MetaDataSummaryInfo />
        <div className="my-3">
          <SummaryFooter
            handlePrevious={handlePrevious}
            checkboxProps={checkboxProps}
            handleOpenModal={handleOpenModal}
          />
        </div>
        <SplModal
          show={showModal}
          handleClose={handleCloseModal}
          title={`Create ${splGeneralData?.tokenSymbol} token`}
        >
          <PaymentInfo
            // accountBalance={balance}
            platformFee={PLATFORM_FEE}
            onClick={createTokenHandler}
          />
        </SplModal>
      </div>
    </>
  );
};

export default SplSummary;
