import axios from "axios";

export const sendDeployStatus = async (
  tokenName,
  WalletAddress,
  address,
  chainId,
  id
) => {
  try {
    const deployResponse = await axios.put(
      "https://trendifytokens.io/api/files/updatdeploystatus",
      {
        tokenname: tokenName,
        walletaddress: WalletAddress,
        contactaddress: address,
        deploystatus: true,
        chainId: chainId?.toString(),
        id: id,
      }
    );

    const res = await deployResponse.data;
    return res;
  } catch (error) {
    console.log(error);
  }
};
