import React, { useId } from "react";
import Product1 from "../../Images/Home/product1.png";
import Product1Logo from "../../Images/Home/product1logo.png";
import Product2 from "../../Images/Home/product2.png";
import Product2Logo from "../../Images/Home/product2logo.png";
import Product3 from "../../Images/Home/product3.png";
import Product3Logo from "../../Images/Home/product3logo.png";
import Product4 from "../../Images/Home/product4.png";
import Product4Logo from "../../Images/Home/product4logo.png";

const ProductCard = ({ data }) => {
  return (
    <div className="product-card-container">
      <div>
        <img src={data.bgImg} alt={data.bgImg} style={{ width: "100%" }} />
      </div>
      <div>
        <img src={data.logo} alt={data.logo} className="product-card-logo" />
      </div>
      <p className="product-card-text">{data.description}</p>
      <div className="d-flex justify-content-center">
        <a href={data.link} target="_blank" className="product-button">
          Checkout Now
        </a>
      </div>
    </div>
  );
};

const Products = () => {
  const id = useId();
  const productData = [
    // {
    //   id,
    //   bgImg: Product1,
    //   logo: Product1Logo,
    //   description:
    //     "The all-in-one product creation, and expansive cross-chain avatars.",
    //   link: "https://ttavatars.io/",
    // },
    {
      id,
      bgImg: Product2,
      logo: Product2Logo,
      description:
        "Get your Website Designed and Published using AI, with TrendifyWeb.",
      link: "https://trendifyweb.ai/",
    },
    {
      id,
      bgImg: Product3,
      logo: Product3Logo,
      description:
        "Watch Ads and earn rewards or be a Advertisers only on Trendads.",
      link: "https://trendads.ai/",
    },
    {
      id,
      bgImg: Product4,
      logo: Product4Logo,
      description:
        "Swap your any Coin with your favorite one now with Trenddx.",
      link: "https://trenddx.io/",
    },
  ];
  return (
    <section id="products">
      {/* Main Container */}
      <div className="product-container">
        {/* Products Header */}
        <div className="text-center mb-4">
          <p style={{ color: "var(--home-text-color)" }}>Products</p>
          <p className="product-heading">Explore Trend Products</p>
        </div>
        {/* Cards Main Container */}
        <div className="d-flex flex-wrap gap-3 justify-content-center">
          {productData?.map((card) => (
            <ProductCard data={card} key={card.id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Products;
