import React, { useState } from "react";
import User from "../../Images/Home/user.svg";
import BaseLineToken from "../../Images/Home/baselinetoken.svg";
import { Link } from "react-router-dom";
import PDF from "../../../src/TOKENCREATOR.pdf";
import VideoModal from "./VideoModal";
const TutorialSteps = ({ data }) => {
  return (
    <>
      <div
        className="d-flex align-item-start gap-2"
        style={{ marginBottom: "2rem" }}
      >
        <div className="circle">
          <p>{data.id}</p>
        </div>
        <div>
          <p className="text-light mb-2">{data.title}</p>
          <p style={{ color: "#9B9797" }}>{data.subHeading}</p>
        </div>
      </div>
    </>
  );
};
const Tutorial = () => {
  const tutorialData = [
    {
      id: 1,
      title: "Connect Web3 Wallet",
      subHeading:
        "Connect your wallet to the Trendifytokens.io from which you want to deploy your token.",
    },
    {
      id: 2,
      title: "Select preferred features",
      subHeading:
        "Select which features you want to have in your token and values that you want to assign to them.",
    },
    // {
    //   id: 3,
    //   title: "Test for free",
    //   subHeading:
    //     "Deploy your token on Testnet to make sure the selected token configuration is right for your needs.",
    // },
    {
      id: 3,
      title: "Deploy on Mainnet",
      subHeading:
        "When you are ready, deploy your token on Mainnet and easily manage its functionality.",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  return (
    <section className="home-wrapper">
      {/* Main Container */}
      <div className="tutorial-container">
        <div className="text-center mb-4">
          <p style={{ color: "var(--home-text-color)" }}>Tutorials</p>
          <h2 className="tutorial-heading">How to generate first token?</h2>
          <p style={{ color: "var(--home-text-gray)" }}>
            We want users to{" "}
            <span className="text-light">become creators.</span>
          </p>
        </div>
        {/* Lower part */}
        <div className="p-3 d-flex flex-column flex-md-row gap-5 gap-md-3 align-items-md-center">
          {/* Lower Left */}
          <div>
            <div className="tutorial-img">
              <img src={BaseLineToken} alt="baselinetoken" />
            </div>
            {/* Card */}
            <div className="tutorial-user-card-container">
              <div className="tutorial-user-card">
                <p>Create your first token on trendifytokens.io</p>
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <img src={User} alt="user" />
                  </div>
                  <div>
                    <p style={{ color: "var(--home-text-gray)" }}>
                      Jenny (trendifytokens.io)
                    </p>
                    <p style={{ color: "var(--home-text-gray)" }}>
                      5 min read - Feb 18 2024
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-center gap-4">
                <a
                  href={PDF}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tutorial-docs-btn"
                >
                  Read Docs
                </a>
                <button
                  className="tutorial-check-btn rm-border"
                  onClick={handleModalOpen}
                >
                  Check Tutorials
                </button>
              </div>
            </div>
          </div>
          {/* Lower Right */}
          <div>
            <div>
              {tutorialData.map((item) => (
                <TutorialSteps data={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <VideoModal show={isModalOpen} onHide={handleModalClose} />
    </section>
  );
};

export default Tutorial;
