import React, { useState } from "react";
import logo from "../Images/Home/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import useClickOutside from "../Hooks/Navigation/useClickOutside";
import { Link, NavLink } from "react-router-dom";
import { HiBars3, HiXMark } from "react-icons/hi2";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletDisconnectButton,
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import "@solana/wallet-adapter-react-ui/styles.css";

const SplTokenNavbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const sidebarRef = useClickOutside(isSidebarOpen, setIsSidebarOpen);
  const handleSidear = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav className="navbar-container py-3 flex sticky-top">
      <div>
        <Link to="/">
          <img src={logo} alt="logo" className="logo" />
        </Link>
      </div>
      {/* NAv for small Devices */}
      <div className="d-lg-none">
        <HiBars3 color="white" size="1.5rem" onClick={handleSidear} />
      </div>
      {isSidebarOpen && (
        <div className="overlay">
          <div className="navbar-sidebar" ref={sidebarRef}>
            <div className="d-flex justify-content-end m-3">
              <HiXMark size="1.5rem" onClick={handleSidear} />
            </div>
            <ul>
              <li>
                <NavLink
                  to="/spltoken"
                  className={({ isActive }) =>
                    `link ${isActive ? "activeLink" : ""}`
                  }
                >
                  Create Token
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={({ isActive }) =>
                    `mx-2 link ${isActive ? "activeLink" : ""}`
                  }
                >
                  Dashboard
                </NavLink>
              </li>
              <WalletModalProvider>
                <div>
                  <div>
                    {/* <WalletDisconnectButton /> */}
                    <WalletMultiButton />
                  </div>
                </div>
              </WalletModalProvider>
            </ul>
          </div>
        </div>
      )}

      {/* Nav for Large Devices */}
      <ul className="d-none d-lg-flex justify-content-md-between mt-3">
        <div className="d-flex align-items-center gap-md-3 gap-lg-3">
          <li>
            <NavLink
              to="/spltoken"
              className={({ isActive }) =>
                `link ${isActive ? "activeLink" : ""}`
              }
            >
              Create Token
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#"
              className={({ isActive }) =>
                ` mx-3 link ${isActive ? "activeLink" : ""}`
              }
            >
              Dashboard
            </NavLink>
          </li>

          <WalletModalProvider>
            <div>
              <div>
                {/* <WalletDisconnectButton /> */}
                <WalletMultiButton />
              </div>
            </div>
          </WalletModalProvider>
        </div>
      </ul>
    </nav>
  );
};

export default SplTokenNavbar;
